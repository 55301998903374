
import { fireClickstreamEvent } from './utilities';
import { localProp } from './properties';

export const trackingPageNames = {
    login: "auth.login",
    notfound: 'auth.notfound',
    empty: 'auth.empty',
    verify: 'auth.mfa',
    forgot: 'forgot.password',
    change: 'change.password',
    finraRedeem: 'finra.redeem',
    forum_register: 'forum.register',
    forum_register_thankyou: 'forum.register.thankyou',
};
let trackingObj = {
    trackingEnabled: true
};
export const logCSPageLoad = (locPath) => {
    const trackCSEvent = fireClickstreamEvent;
    if (typeof trackCSEvent !== "undefined" && typeof trackCSEvent === "function") {
        let csName = '';
        switch (locPath) {
            case '/empty':
                csName = trackingPageNames.empty;
                break;
            case '/verify':
                csName = trackingPageNames.verify;
                break;
            case '/forgot-password':
                csName = trackingPageNames.forgot;
                break;
            case '/change-password':
                csName = trackingPageNames.change;
                break;
            case '/saveandinvest':
                csName = trackingPageNames.finraRedeem;
                break;
            case '/forum-register-thankyou':
                csName = trackingPageNames.forum_register_thankyou;
                break;
            case '/forum-register':
                csName = trackingPageNames.forum_register;
                break;
            case '/':
                if (window.location.href.indexOf("saveandinvest") > -1) {
                    csName = trackingPageNames.finraRedeem;
                } else if (window.location.href.indexOf("forum-register") > -1) {
                    if (window.location.href.indexOf("thankyou") > -1) {
                        csName = trackingPageNames.forum_register_thankyou;
                    } else {
                        csName = trackingPageNames.forum_register;
                    }
                    
                } else {
                    csName = trackingPageNames.login;
                }
                break;
            default:
                csName = trackingPageNames.notfound;
        }
        if (csName && trackingObj.trackingEnabled) {
            trackingObj.trackingEnabled = false;
            if (localProp.testLocal && console && console.log) console.log(`CLICKSTREAM - csName: ${csName} , Pathname ['${locPath}']`)
            if (window && window.myfcsObj) {
                window.myfcsObj.pageName = csName;
            }
            trackCSEvent({ 'type': 'display', 'name': csName });
            setTimeout(() => {
                trackingObj.trackingEnabled = true;
            }, 2000)
        }
    } else {
        if (localProp.testLocal && console && console.log) console.log(`Pathname ['${locPath}']: CS not availabe`)
    }
}