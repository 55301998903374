
import {trimStr} from '../../utils/utilities';

  // don't check for special char, let api handle
export const validateUserId = (loginID) =>  {
    //const strRegExp = /^\s*[a-zA-Z0-9_\-\@\.]{6,80}\s*$/;
    //const strSSNRegExp = /^\s*[0-9]{9}\s*$/;

    loginID = trimStr(loginID);

    if(loginID.length === 0)
        return 'Required';

    // if(loginID.length < '6')
    //     return 'User ID must be at least 6 characters.';

    
    // if (!strRegExp.test(loginID))
    //     return 'User ID can only contain letters, numbers and the special characters @ . - _';

    // if (strSSNRegExp.test(loginID))
    //     return 'User ID may not be a nine digit number.';

    return true;
}

export const validPassword = (password) => {
    const strRegExp = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/;
    //let count = 0;

    password = trimStr(password);

    if(password.length === 0)  
        return 'Required';    
    // else if (password.length < 8) {
    //     return 'Your password must be at least 8 characters.';
    // } else if (password.length > 128) {
    //     return 'Your password must be between 8 and 128 characters.';
    // } else {
    //     if (strRegExp.test(password) === true && password.length > 0) {
    //         count++;
    //     } 
    //     if (/[A-Z]/.test(password) === true) {
    //         count++
    //     }
    //     if (/[a-z]/.test(password) === true) {
    //         count++
    //     }
    //     if (/\d/.test(password) === true) {
    //         count++
    //     }

    //     //error only less than three matches
    //     if (count < 3) {
    //         return 'Your password must be between 8 and 128 characters and must contain a mix of uppercase, lowercase, numbers and special characters. (At least 3 types). Please re-enter your password.';
    //     }
    // }
    return true;
}