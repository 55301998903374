import { svgIcons } from '../../utils/svgs';
import appQrCode from '../../assets/images/app-store-qr-code.svg';

const AppTeaser = () => {
    return (
        <>
            <div className="app-teaser-sm">
                <ul className="link-group-list app-store-list">
                    <li>
                        <a className="prop10Event cselem" data-cs-name="get.ios.app" data-cs-type="click" target="_blank" href="//itunes.apple.com/us/app/myfico-mobile/id714626557?mt=8">
                            <span className="app-badge app-store-badge" aria-hidden="true">
                                <svg data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.appIos }}></svg>
                            </span>
                            <div className="sr-only">Download iOS app on the App Store</div>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" className="prop10Event cselem" data-cs-name="get.android.app" data-cs-type="click" href="//play.google.com/store/apps/details?id=com.myfico&hl=en">
                            <span className="app-badge google-play-badge" aria-hidden="true">
                                <svg data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.appAndroid }}></svg>
                            </span>
                            <div className="sr-only">Get Android app on the Google Play Store</div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="app-teaser-lg">
                <div className="divider-grey"></div>
                <div className="d-flex">
                    <div className="mr-1">
                        <h2>myFICO App</h2>
                        <p>Scan to check your FICO<sup aria-hidden="true">&reg;</sup> Score and credit data while on the go.</p>
                    </div>
                    <div>
                        <img 
                            alt="QR code to download the myFICO App from the App Store or Play Store" 
                            src={appQrCode}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppTeaser;