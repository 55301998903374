import * as actionTypes from '../actions/actionTypes';

export const initialState = {
    errorType: {
        show: false,
        msg: ''
    },
    pageLoaded: false
}

export const finraReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FINRA_VOUCHER_ERROR:
            return {
                ...state,
                errorType: action.payload,
            }
        case actionTypes.FINRA_VOUCHER_PAGELOADED:
            return {
                ...state,
                pageLoaded: action.payload,
            }
        default:
            return state;
    }
};

export default finraReducer;