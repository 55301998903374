
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { svgIcons } from '../../utils/svgs';
import * as utils from '../../utils/utilities';
import { logCSPageLoad } from '../../utils/tracking';
import FinraMeta from './FinraMeta';
import './FinraRedeem.css';
import $ from 'jquery';
import SimpleButton from '../../components/SimpleButton/SimpleButton';
import * as SimpleButtonAction from '../../components/SimpleButton/SimpleButton';
import VoucherRedeemApi from '../../api/VoucherRedeemApi';
import * as constants from '../../utils/constants';
import * as actions from '../../store/actions/actions';

export const redeemHandler = (e) => {

    return (dispatch) => {
        const currentId = e && e.currentTarget && e.currentTarget.id;
        SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);

        const selectId = $(".1bcr-purchase-select-bureau .ui-radio-item").find("input:checked").attr("id");
        let selectedBureau = "";
        let selectedProdId = "";
        switch (selectId) {
            case "eq-1bcr": selectedBureau = "equifax"; selectedProdId = "1"; break;
            case "tu-1bcr": selectedBureau = "transunions"; selectedProdId = "10001"; break;
            case "ex-1bcr": selectedBureau = "experian"; selectedProdId = "20001"; break;
            default: selectedBureau = "transunions"; selectedProdId = "10001"; break;
        }

        $(`#${currentId}`).attr("data-cs-category", selectedBureau);

        //remove dash on api
        const qs = utils.getQS('vcode') || utils.qsSearch().get('vcode');
        let val = "";
        const inputVal = $("#input_voucher").val();

        if (inputVal && inputVal.length > 1) {
            val = inputVal;
        } else if (qs && qs.length > 1) {
            val = qs;
        }
        const data = {
            vid: val,
            pid: selectedProdId
        }
        if (val) {
            dispatch(VoucherRedeemApi(data, e));
        } else {
            dispatch(actions.setFinraErrorType({ 'show': true, 'msg': constants.verifyVar.missing }));
            SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
        }

    }
}

const primaryButton = {
    id: 'redeem',
    buttonData: 'text',
    csName: 'redeem.btn',
    text: 'Redeem',
    action: redeemHandler,
    ariaLabel: 'Redeem',
    dispatch: true,
    classes: 'csjdo firstOrIntermediateTouch'
}

export const formatVoucher = (text) => {
    const noChar = /\W/g;
    const formatDash = /[\d\w]{4}(?=[\d\w]{2,3})|[\d\w]+/g;

    if (text) {
        let trimTxt = utils.trimStr(text)
        const removeSpecial = trimTxt.replace(noChar, '');

        //console.log('typef',trimTxt,  removeSpecial)
        const formatTxt = removeSpecial.match(formatDash).join("-");
        //console.log('before', removeSpecial, '  after: ', formatTxt )

        return formatTxt;
    }
    return text;
}

export const UserItems = {
    id: 'input-container-voucher',
    inputId: 'input_voucher',
    description: 'Example: A1A1-B2B2-C3C3-D4D4-E5E5',
    type: 'text',
    ariaLabel: 'Voucher code',
    currentValue: '',
    helperId: 'voucher_helper',
    emptyErrorMessage: 'Required'
};

export const enterInputHandler = (e) => {
    //console.log('enter, e', e)
}
export const onKeyUp = (e) => {
    // console.log('onkeyup, e ', e)
}
export const onBlurText = (e) => {
    const input = $("#input_voucher").val();
    $("#input_voucher").val(formatVoucher(input));


    return dispatch => {
        //clear error message
        if (input) {
            dispatch(actions.setFinraErrorType({ 'show': false, 'msg': '' }));
        }
    }
}

export const checkRadio = (e) => {
    const clickedRadioRow = $(".1bcr-purchase-select-bureau .ui-radio-item");
    $(clickedRadioRow).click(function () {
        $(this)
            .find("input[type=radio]")
            .focus()
            .prop("checked", true);
    });
}

export const displayRedeemInput = () => {
    return (dispatch, getState) => {
        const finraItems = getState().finra && getState().finra.errorType && getState().finra.errorType.show;

        const qs = utils.getQS('vcode') || utils.qsSearch().get('vcode');


        if ((qs && qs.length > 1) && !finraItems) {
            const inputVal = $("#input_voucher").val();
            if (inputVal && inputVal.length > 1) {
                return true
            }
            return false;
        }
        return true;
    }
}


const FinraRedeem = (props) => {

    const dispatch = useDispatch();
    const finraItems = useSelector(state => state.finra);
    const { location } = props;

    useEffect(() => {
        let isLocationCall = true;

        if (typeof location !== "undefined" && isLocationCall) {
            const locPath = location.pathname;
            logCSPageLoad(locPath);
        }

        return () => {
            isLocationCall = false;
        }
    }, []);


    //call voucher api on pageload when vscode querystring is found to validate voucher
    useEffect(() => {
        let isPageLoaded = true;
        const qs = utils.getQS('vcode') || utils.qsSearch().get('vcode');

        if (typeof finraItems !== "undefined" && isPageLoaded && qs && qs.length > 1) {
            const data = {
                vid: qs,
                loadonly: true
            }
            dispatch(VoucherRedeemApi(data));
        }

        return () => {
            isPageLoaded = false;
        }
    }, []);


    return (
        <div id="myf-content">
            <FinraMeta />
            <div className="bg auth-wrapper" data-testid="finra-redeem-main">
                <div className="finra-redeem-page auth-module">
                    <h1 className="voucher-headline">Redeem your free FICO<sup aria-hidden="true">&reg;</sup> Score</h1>
                    <div className="voucher-wrap">
                        {dispatch(displayRedeemInput()) ?
                            <p className="voucher-para" data-testid="voucher-para">To redeem your free FICO<sup aria-hidden="true">&reg;</sup> Score and credit report, please enter your 20-digit voucher code below:</p>
                            :
                            <p className="voucher-para" data-testid="voucher-para">The voucher code you entered entitles you to a free single bureau credit report from TransUnion, Equifax or Experian.</p>
                        }
                        {dispatch(displayRedeemInput()) &&
                            <React.Fragment>
                                {finraItems && finraItems.errorType && finraItems.errorType.show &&
                                    <div className="input-helper-container api-error-container" data-testid="voucher-error">
                                        <div className="error-message" aria-live="polite">
                                            <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                            <span className="text-container" dangerouslySetInnerHTML={{ __html: finraItems.errorType && finraItems.errorType.msg }}></span>
                                            {/* {loginItems && loginItems.errorType[UserItems.inputId].show && <span className="text-container">{loginItems.errorType[UserItems.inputId].msg}</span>} */}
                                        </div>
                                    </div>
                                }
                                <div data-component="Input" data-type="TextField" id={UserItems.id} data-valid="">
                                    <input data-testid="voucher-input" type={UserItems.type} id={UserItems.inputId} aria-label={UserItems.ariaLabel} aria-describedby={UserItems.helperId} placeholder=" " onChange={e => (enterInputHandler(e))} onBlur={e => (dispatch(onBlurText(e)))} onKeyPress={e => ((onKeyUp(e)))} maxLength="24" required />
                                    <div className="input-label" aria-hidden="true">{UserItems.ariaLabel}</div>
                                    <div className="input-helper-container note" id={UserItems.helperId} aria-live="polite">
                                        <div className="description">{UserItems.description}</div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="redeem-wrap">
                        <p className="bureau-select" data-testid="voucher-bureau-title">Choose a credit bureau:</p>
                        <form className="1bcr-purchase-select-bureau" name="1bcr-form" aria-label="Select a bureau" role="radiogroup">
                            <div className="ui-radio-group-stacked" data-testid="voucher-radio-group">
                                <div className="ui-radio-item" onClick={e => checkRadio(e)}><input aria-label="TransUnion" className="sr-only" type="radio" id="tu-1bcr" data-testid="tu-1bcr" name="1bcr" defaultChecked /><label aria-hidden="true" htmlFor="tu-1bcr"><span className="ui-radio" aria-hidden="true" ></span>TransUnion</label></div>
                                <div className="ui-radio-item" onClick={e => checkRadio(e)}><input aria-label="Equifax" className="sr-only" type="radio" id="eq-1bcr" data-testid="eq-1bcr" name="1bcr" /><label aria-hidden="true" htmlFor="eq-1bcr"><span className="ui-radio" aria-hidden="true"></span>Equifax</label></div>
                                <div className="ui-radio-item" onClick={e => checkRadio(e)}><input aria-label="Experian" className="sr-only" type="radio" id="ex-1bcr" data-testid="ex-1bcr" name="1bcr" /><label aria-hidden="true" htmlFor="ex-1bcr"><span className="ui-radio" aria-hidden="true"></span>Experian</label></div>
                            </div>
                        </form>
                        <div className="full-width-btn redeem-btn" data-testid="voucher-btn">
                            <SimpleButton {...primaryButton} />
                        </div>
                    </div>
                    <div className="foot-wrap">
                        <p className="bold foot-sub" data-testid="foot-sub-title">Need help choosing?</p>
                        <p className="foot-para" data-testid="foot-sub-para">Each credit bureau keeps separate records which means that your FICO<sup aria-hidden="true">&reg;</sup> credit score may differ between them. If you're currently shopping for a loan, your lender may let you know which bureau they will be checking.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(FinraRedeem);