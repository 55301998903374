import React from "react";
import { Helmet } from "react-helmet";
import * as utils from '../../utils/utilities';

export const checkFinraTitle = () => {
    let title = '';

    if (utils.isFinra()) {
        title = " | FINRA Investor Education Foundation";
    }

    return title;
}

const ForgotPasswordMeta = (props) => {
    return (
        <Helmet>
            <title>{`Forgot Member Info | myFICO ${checkFinraTitle()}`}</title>
            <meta name="description" content="Identify your account to reset account password" />
        </Helmet>
    );
};

export default ForgotPasswordMeta;