import axiosConfig from './../utils/axiosConfig';
import { apiUri, localProp, properties } from './../utils/properties';
import { checkForReUrl, fireClickstreamEvent } from './../utils/utilities';
import * as actions from '../store/actions/actions';
import * as Constants from '../utils/constants';
import * as loginActions from '../containers/Login/Login';
import supportContact from './SupportContactApi';
import * as SimpleButtonAction from '../components/SimpleButton/SimpleButton';
import history from '../utils/history';
import * as utils from '../utils/utilities';


const LoginApi = (data, e) => {
    const authTokenURL = "https:" + properties.authServer + apiUri.getNewToken;
    const currentId = e && e.currentTarget && e.currentTarget.id;

    return (dispatch) => {
    
        if (typeof data !== "undefined") {
            return axiosConfig.post(authTokenURL, data)
            .then(res => {
                if (localProp.testLocal && console && console.log) console.log('post called', res)
                if (res && res.data && res.data.response) {
                    if (localProp.testLocal && console && console.log) console.log('res data', res.data)
                    window.location = checkForReUrl();
                }
            })
            .catch(function (err) {
                loginActions.resetPasswordField();
                loginActions.focusUserInputField();

                if (err && err.response && err.response.data && err.response.data.error) {
                    if (err.response.data.error.code === "MFARequired" && err.response.data.error.additionalInfo) {
                        history.push('/verify');
                        dispatch(actions.setLoginAPIError(''));
                        dispatch(actions.setAuthInfo(err.response.data.error.additionalInfo));
                    } else if (err.response.data.error.code === "UnknownException") {
                        dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.default));
                        fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'unknown_exception'});
                    } else if (err.response.data.error.code === "LoginFailed") {
                        if (err.response.data.error.message.indexOf("FailedLogin") > -1) {
                            dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.FailedLogin));
                            fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'failed_login'});
                        }
                        if (err.response.data.error.message.indexOf("ForgotMemberInfo") > -1) { 
                            dispatch(actions.setLoginAPIError(''));
                            fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'forgot_member_info'});

                            //redirect user to forgot password url
                            SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
                            loginActions.redirectForgotPasswordLink();
                        }
                        if (err.response.data.error.message.indexOf("AccountChargeBackLock") > -1) { 
                            dispatch(supportContact("AccountChargeBackLock"));
                        }
                        if (err.response.data.error.message.indexOf("AccountAdminLock") > -1) { 
                            dispatch(supportContact("AccountAdminLock"));
                        } 						
                        if (err.response.data.error.message.indexOf("AccountOtherLock") > -1) { 
                            dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.AccountOtherLock));
                            fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'account_other_lock'});
                        }
                        if (err.response.data.error.message.indexOf("AccountLocked") > -1) { 
                            dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.AccountLocked));
                            fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'account_locked'});
                        }
                        if (err.response.data.error.message.indexOf("AccountMFALock") > -1) { 
                            dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.LockOut));
                            fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'mfa_account_locked'});
                        }
                    } else if (err.response.data.error.code === "ExternalApiInvocationFailed") {
                        dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.ExternalApiInvocationFailed));
                        fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'external_api_invocation_failed'});
                    } else {
                        dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.default));
                        fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'default'});
                    }
                } else {
                    dispatch(actions.setLoginAPIError(Constants.loginErrorMsg.default));
                    fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': 'default'});
                }

                //disable the loader on button
                SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
                if (localProp.testLocal && console && console.log) console.log('error', err.response.data.error)
            })
        }
    }
}

export default LoginApi;