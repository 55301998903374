import axiosConfig from '../utils/axiosConfig';
import { apiUri, localProp, properties } from '../utils/properties';
import { checkForReUrl, fireClickstreamEvent } from '../utils/utilities';
import * as actions from '../store/actions/actions';
import * as Constants from '../utils/constants';
import * as loginActions from '../containers/Login/Login';
import supportContact from './SupportContactApi';
import * as SimpleButtonAction from '../components/SimpleButton/SimpleButton';
import history from '../utils/history';
import * as utils from '../utils/utilities';

const VoucherRedeemApi = (data, e) => {
    let vocherRedeemURL = "https:" + properties.apiServer + apiUri.voucherURL;
    const currentId = e && e.currentTarget && e.currentTarget.id;
    return (dispatch, getState) => {
        if (typeof data !== "undefined" && data.vid) {
            const finraError = getState().finra && getState().finra.errorType && getState().finra.errorType.show;
        
            //remove dashes
            const formatVid = data.vid.replace(/[^a-zA-Z0-9]/g, '');
            vocherRedeemURL = vocherRedeemURL.replace('{vid}', formatVid);        

            return axiosConfig.get(vocherRedeemURL)
            .then(res => {
                const resData = res && res.data
                if (resData && resData.valid_code) {
                    if (data.loadonly) {
                        //load on page load to check if voucher is valid.
                        //don't need to do anything if valid, will make second call after product selection 
                        if (!finraError) {
                            dispatch(actions.setFinraErrorType({'show': false, 'msg': ''}));                       
                        }
                    } else {
                        //redirect url to purchase on clicking redeeem btn
                        const purchaseUrl = properties.securewebserver + "/order/start?product=" + data.pid + "&skin=FINRA";
                        window.location.href= purchaseUrl;
                    }
                } else {
                    //default error message
                    dispatch(actions.setFinraErrorType({'show': true, 'msg': Constants.loginErrorMsg.default.message}));
                    fireClickstreamEvent({'type': 'display', 'name': 'finra.error.msg', 'category': 'invalid code'});
                }
            })
            .catch(function (err) {
                //message from api
                if (err && err.response && err.response.data && err.response.data.error && err.response.data.error && err.response.data.error.message) {
                    dispatch(actions.setFinraErrorType({'show': true, 'msg': err.response.data.error.message}));
                    fireClickstreamEvent({'type': 'display', 'name': 'finra.error.msg', 'category': 'api'});
                } else {
                    //generic error message
                    dispatch(actions.setFinraErrorType({'show': true, 'msg': Constants.loginErrorMsg.default.message}));
                    fireClickstreamEvent({'type': 'display', 'name': 'finra.error.msg', 'category': 'default'});
                }

                //disable the loader on button
                SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
            })
        } else {
            //when vid is empty -- display error message
            dispatch(actions.setFinraErrorType({'show': true, 'msg': 'Sorry! The voucher code you have entered is invalid. Please try again, or contact <a class="error-link" href="mailto:CreditScore@finra.org">SaveAndInvest.org</a> for questions.'}));
            fireClickstreamEvent({'type': 'display', 'name': 'finra.error.msg', 'category': 'no voucher'});
            SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
        }
    } 
}

export default VoucherRedeemApi;