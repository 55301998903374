import * as actionTypes from '../actions/actionTypes';

export const initialState = {
    errorType: {
        input_user: {
            show: false,
            msg: ''
        },
        input_password: {
            show: false,
            msg: ''
        },
        verify_code: {
            show: false,
            msg: ''
        }
    },
    mask: {
        input_password: {isAriaPressed: false, icon: 'hide', type: 'password'}
    },
    apiResError: {
        message: ''
    },
    authInfo: {},
    authNewInfo: {},
    emailPopup: {
        open: false
    },
    showEmail: false
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_PASSWORD_MASKING:
            return {
                ...state,
                mask: action.payload,
            }
        case actionTypes.LOGIN_INLINE_ERROR:
            return {
                ...state,
                errorType: action.payload,
            }
        case actionTypes.LOGIN_API_ERROR:
            return {
                ...state,
                apiResError: action.payload,
            }
        case actionTypes.LOGIN_AUTH_INFO:
            return {
                ...state,
                authInfo: action.payload
            }
        case actionTypes.LOGIN_AUTH_NEW_INFO:
            return {
                ...state,
                authNewInfo: action.payload
            }
        case actionTypes.LOGIN_EMAIL_POPINFO:
            return {
                ...state,
                emailPopup: action.payload
            }
        case actionTypes.LOGIN_SHOW_EMAIL:
                return {
                    ...state,
                    showEmail: action.payload
                }
        default:
            return state;
    }
};

export default loginReducer;