const trim = (str) => {
    return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}

export const checkPassword = (passVal) => {
    //for Forum register page only
    var capitalResult = false,
        lowerResultResult = false,
        specialResult = false,
        numberResult = false,
        digitResult = false,
        count = 0;

    const pwFlags = {
        is_empty: true,
        length: false,
        letter_and_number: false
    }

    // checking lenght
    if (passVal.length < 8 || passVal.length > 128) {
        pwFlags.length = false;
        numberResult = false;
    } else {
        pwFlags.length = true;
        numberResult = true;
    }

    //empty state
    if (passVal.length == 0) {
        pwFlags.is_empty = true
    } else {
        pwFlags.is_empty = false
    }

    //step 1
    //check for upper case
    if (/[A-Z]/.test(passVal) == true) {
        capitalResult = true;
    }
    else {
        capitalResult = false;
    }
    //check for digit
    if (/[0-9]/.test(passVal) == true) {
        digitResult = true;
    }
    else {
        digitResult = false;
    }
    //check for lower case
    if (/[a-z]/.test(passVal) == true) {
        lowerResultResult = true;
    } else {
        lowerResultResult = false;
    }
    //check for supported special chars
    if (/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/.test(passVal) == true && passVal.length > 0) {
        specialResult = true;
    }
    else {
        specialResult = false;
    }

    //step2
    //Add the count: need at least 3 to pass
    if (capitalResult) {
        count += 1;
    }

    if (lowerResultResult) {
        count += 1;
    }

    if (digitResult) {
        count += 1;
    }

    if (specialResult) {
        count += 1;
    }

    //step 3
    if (count >= 3) {
        pwFlags.letter_and_number = true;
    } else {
        pwFlags.letter_and_number = false;
    }

    return pwFlags
}

export const checkRePassword = (passVal, rePassValue) => {
    return passVal === rePassValue ? true : false
}

export const checkName = (nameVal, nameType) => {
    var nameString = ''
    var strRegExp = '';

    nameVal = trim(nameVal);

    if (nameType == 1) {
        nameString = 'First name';
        // note: this regex limits the character length to 30
        strRegExp = /^\s*[a-zA-Z \'\-]{1,30}\s*$/;
        if (nameVal.length == 0)
            return nameString + ' is required.';

        if (!strRegExp.test(nameVal))
            return nameString + ' can only contain letters, spaces, dashes and apostrophes.';
    } else {
        nameString = 'Last name';
        // note: this regex limits the character length to 30
        strRegExp = /^\s*[a-zA-Z \'\-\.]{1,30}\s*$/;
        if (nameVal.length == 0)
            return nameString + ' is required.';

        if (!strRegExp.test(nameVal))
            return nameString + ' can only contain letters, spaces, dashes, periods and apostrophes.';
    }
    return false;
}

export const checkAddress = (streetVal) => {
    var strRegExp = /[a-zA-Z\d\s\-\,\#\.\+\/]+/;
    var strRegExp2 = /^[a-zA-Z0-9\s,#'-\.\/]*$/;
    streetVal = trim(streetVal);

    if (streetVal.length == 0)
        return 'Street address is required.';

    if (!strRegExp.test(streetVal) || !strRegExp2.test(streetVal))
        return 'Street address can only contain letters, numbers and the special characters / . , # -';

    return false;
}
export const checkZip = (zipVal) => {
    var strRegExp = /^\s*[0-9]{5}\s*$/;
    zipVal = trim(zipVal);
    if (zipVal.length == 0)
        return 'Zip Code is required.';
    if (zipVal.length < 5)
        return 'Zip Code is incomplete.';
    if (!strRegExp.test(zipVal))
        return 'Zip Code can only contain numbers.';
    if (zipVal == 'invalid')
        return 'Zip-code not found. Please re-enter your zip';
    return false;
}
export const checkCity = (cityVal) => {
    var strRegExp = /^\s*[a-zA-Z \.]{1,50}\s*$/;

    cityVal = trim(cityVal);
    if (cityVal.length == 0)
        return 'City is required.';
    if (!strRegExp.test(cityVal))
        return 'City can only contain letters, spaces and periods.';
    return false;
}
export const checkState = (stateVal) => {
    if(stateVal.length == 0)
        return 'State is required.';

    if (stateVal.length < 2)
        return 'State is invalid.';

    var VALID_STATES = '|AL|AK|AS|AZ|AR|AA|AE|AP|CA|CO|CT|DE|DC|FL|FM|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|MH|MP|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|PR|PW|RI|SC|SD|TN|TX|UT|VT|VA|VI|WA|WV|WI|WY|';

    if (VALID_STATES.indexOf(stateVal, 0) < 0)
        return "State is invalid.";

    return false;
}
export const checkEmail = (emailVal) => {
    var strRegExp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    emailVal = trim(emailVal);           
    if (emailVal.length == 0)  
        return 'Email Address is required.';    
    if (!strRegExp.test(emailVal))  
        return 'Email Address is improperly formatted or contains invalid characters.';    
    return false;
}
export const checkPhone = (phoneVal) => {
    var strRegExp = /^\s*[0-9]{10}\s*$/;
    phoneVal = trim(phoneVal).replace(/\D/g, "");
    if (phoneVal.length == 0)
        return 'Phone Number is required.';
    if (phoneVal.length < 10)    
        return 'The Phone Number field is incomplete and needs to be 10 digits in the format of nnn nnn nnnn.';
    if (!strRegExp.test(phoneVal))    
        return 'The Phone Number field only allows numeric characters. Please re-enter your Phone Number.';
    if (phoneVal.charAt(0) == '0' || phoneVal.charAt(0) == '1')
        return "The Phone Number cannot begin with digit 0 or 1. Please re-enter your Phone Number.";
    return false;
}
export const checkLogonName = (loginIDVal) => {
    var strRegExp = /^\s*[a-zA-Z0-9_\-\@\.]{6,80}\s*$/;
    var strSSNRegExp = /^\s*[0-9]{9}\s*$/;
    loginIDVal = trim(loginIDVal);
    if (loginIDVal.length == '0')
        return 'Please enter your User ID.';
    if (loginIDVal.length < '6')
        return 'User ID must be at least 6 characters.';
    if (!strRegExp.test(loginIDVal))
        return 'User ID can only contain letters, numbers and the special characters @ . - _';
    if (strSSNRegExp.test(loginIDVal))
        return 'User ID may not be a nine digit number.';
    return false;
}