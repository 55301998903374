import React from "react";
import { Helmet } from "react-helmet";
import * as loginHelmActions from '../Login/LoginMeta';

const LoginVerifyMeta = (props) => {
    return (
        <Helmet>
            <title>{`Verify login | myFICO ${loginHelmActions.checkFinraTitle()}`}</title>
            <meta name="description" content="Verify login" />
        </Helmet>
    );
};

export default LoginVerifyMeta;