export const SET_MARK_ALL_FLAG = 'SET_MARK_ALL_FLAG';

export const LOGIN_PASSWORD_MASKING = 'LOGIN_PASSWORD_MASKING';
export const LOGIN_INLINE_ERROR = 'LOGIN_INLINE_ERROR'
export const LOGIN_API_ERROR = 'LOGIN_API_ERROR'
export const LOGIN_AUTH_INFO = 'LOGIN_AUTH_INFO'
export const LOGIN_AUTH_NEW_INFO = 'LOGIN_AUTH_NEW_INFO'
export const LOGIN_EMAIL_POPINFO = 'LOGIN_EMAIL_POPINFO'
export const LOGIN_SHOW_EMAIL = 'LOGIN_SHOW_EMAIL'

export const FINRA_VOUCHER_ERROR = 'FINRA_VOUCHER_ERROR'
export const FINRA_VOUCHER_PAGELOADED = 'FINRA_VOUCHER_PAGELOADED'
