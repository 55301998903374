import { testProps } from './localTestProperties';
import { getQS } from './utilities';
export const localProp = testProps

let webServer = null,
    secureWebserver = null,
    apiServer = null,
    authServer = null,
    classicServer = null;

if (typeof window.myfico == 'object' && typeof window.myfico.globalVars == 'object') {
    webServer = window.myfico.globalVars.webserver || '';
    secureWebserver = window.myfico.globalVars.securewebserver || '';
    apiServer = window.myfico.globalVars.apiserver || '';
    authServer = window.myfico.globalVars.authserver || '';
    classicServer = window.myfico.globalVars.classicserver || '';
}

if (localProp.testLocal) {
    webServer = `//www.${localProp.env}.myfico.com`;
    secureWebserver = `//secure.${localProp.env}.myfico.com`;
    authServer = `//auth.${localProp.env}.myfico.com`;
    apiServer = `//api.${localProp.env}.myfico.com`;
}

export const properties = {
    webserver: webServer,
    securewebserver: secureWebserver,
    apiServer: apiServer,
    authServer: authServer,
    classicServer: classicServer,
    stateArr: ["AL", "AK", "AS", "AZ", "AR", "AA", "AE", "AP", "CA", "CO", "CT", "DE", "DC", "FL", "FM", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"]
};
export const apiUri = {
    authTokenURL : '/v3/users/authtoken',
    supportContactURL : '/v3/supportcontact',
    getNewToken: '/v4/users/authtoken',
    getMFASettings: "/v4/users/auth/mfa/setting",
    verfiyMFACode: "/v4/users/auth/mfa/setting/{mfa_setting_id}",
    requestNewMFACode : "/v4/users/auth/mfa",
    pwReset: properties.authServer + "/v4/users/profile/password/reset",
    voucherURL: '/v4/voucher/{vid}',
    getZip: '/v4/users/zip_lookup',
    personalInfo: properties.securewebserver + '/v4/users/profile/personal_info',
    authTicket: properties.authServer + '/v3/users/authticket'
};
