
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import * as utils from '../../utils/utilities';
import { logCSPageLoad } from '../../utils/tracking';
import * as actions from '../../store/actions/actions';
import * as constants from '../../utils/constants';
import { svgIcons } from '../../utils/svgs';
import './LoginVerify.css'
import LoginVerifyMeta from './LoginVerifyMeta';
import * as loginActions from '../Login/Login';
import * as API from '../../api/LoginVerifyApi';
import * as SimpleButtonAction from '../../components/SimpleButton/SimpleButton';
import * as loginState from '../../store/states/getLogin';

import { Modal } from 'react-responsive-modal';
import { properties } from '../../utils/properties';
//import LoginVerifySelection from './LoginVerifySelection';
//import ModalPopup from '../../components/ModalPopup/ModalPopup';
import SimplePopup from '../../components/SimplePopup/SimplePopup';

export const ErrorMessage = loadable(() => import('../../components/ErrorMessage/ErrorMessage'));
export const SimpleButton = loadable(() => import('../../components/SimpleButton/SimpleButton'));
//export const SimplePopup = loadable(() => import('../../components/SimplePopup/SimplePopup'));
export const LoginVerifySelection = loadable(() => import('./LoginVerifySelection'));

export const VerifyCodeItems = {
    id: 'input-container-verify-code',
    inputId: 'verify_code',
    type: 'text',
    ariaLabel: 'Verification code',
    currentValue: '',
    helperId: 'verify_code',
    emptyErrorMessage: 'Required',
    regErrorMessage: 'The code should be 6 digits.'
};

/** Util helper */

export const lastTwoStr = (data) => {
    if (!data) return '';
    const reg = data.slice(-2);
    return reg;
}

export const getVerifyFromInput = () => {
    return document.getElementById(VerifyCodeItems.inputId) ? document.getElementById(VerifyCodeItems.inputId).value : "";
}
export const getSMSRadioInput = () => {
    return document.getElementById("sms-radio") ? document.getElementById("sms-radio").checked : false;
}
export const getEmailRadioInput = () => {
    return document.getElementById("email-radio") ? document.getElementById("email-radio").checked : false;
}
export const getCheckboxFromInput = () => {
    return document.getElementById('rbox') ? document.getElementById('rbox').checked : false;
}
export const focusVerifyInputField = () => {
    if (document.getElementById(VerifyCodeItems.inputId) !== null) {
        document.getElementById(VerifyCodeItems.inputId).focus()
    }
}
export const clearVerifyInputField = () => {
    if (document.getElementById(VerifyCodeItems.inputId) !== null) {
        document.getElementById(VerifyCodeItems.inputId).value = '';
    }
}

export const checkCodeId = () => {
    const log = loginState.getLoginState();
    let codeId = '';

    const qs_mfa = utils.qsHash().get('mfa');

    if (typeof qs_mfa !== "undefined" && qs_mfa !== null) {
        codeId = utils.sanitizeString(qs_mfa);
    }
    else if (typeof log !== "undefined" && log !== null) {
        if (typeof log.authNewInfo !== "undefined" && typeof log.authNewInfo.mfa_code_id !== "undefined"){
            codeId = log.authNewInfo.mfa_code_id
        } else if (typeof log.authInfo !== "undefined" && typeof log.authInfo.mfa_code_id !== "undefined") {
            codeId = log.authInfo.mfa_code_id
        }
    }
    return codeId;
}

//checkCodeId()

export const checkText = () => {
    const loginItems = loginState.getLoginState();
    let text = constants.verifyVar.SMSSuccess;
    let channelQS = utils.getQS('ch');
    if (typeof loginItems !== "undefined" && loginItems !== null) {
        if (typeof loginItems.authNewInfo !== "undefined" && loginItems.authNewInfo !== null && Object.keys( loginItems.authNewInfo).length > 0) {
            if (typeof loginItems.authNewInfo.channel !== "undefined" && loginItems.authNewInfo.channel !== null)
            {
                if (loginItems.authNewInfo.channel === constants.nameVar.SMS) {
                    text = constants.verifyVar.resendSuccess.replace('{destination}', '(•••) •••-••' + lastTwoStr(loginItems.authNewInfo.destination));
                } else if (loginItems.authNewInfo.channel === constants.nameVar.EMAIL) {
                    text = constants.verifyVar.resendSuccess.replace('{destination}', loginItems.authNewInfo.destination);
                    text = text.replace(/\*/g, '•');
                } 
                if (typeof loginItems.authNewInfo.expires_in !== "undefined" && loginItems.authNewInfo.expires_in !== null) {
                    text = text.replace('{min}', loginItems.authNewInfo.expires_in / 60 );
                }
            }
        } else if (typeof loginItems.authInfo !== "undefined" && loginItems.authInfo !== null && Object.keys(loginItems.authInfo).length > 0) {
            if (loginItems.authInfo.channel === constants.nameVar.EMAIL) {
                text = constants.verifyVar.EMAILSuccess.replace('{email}', loginItems.authInfo.destination);
                text = text.replace(/\*/g, '•');
            } else if (loginItems.authInfo.channel === constants.nameVar.SMS) {
                text = constants.verifyVar.SMSSuccess.replace('{phone}', '(•••) •••-••' + lastTwoStr(loginItems.authInfo.destination));
            } else {
                text = constants.loginErrorMsg.default.message;
            }
        } else if (channelQS !== null && channelQS !== "" && channelQS === "email") { 
            text = constants.verifyVar.mobileConfirm.replace('texted','emailed');
        } else {
            text = constants.verifyVar.mobileConfirm;
        }
    } else {
        text = constants.verifyVar.mobileConfirm;
    }

    return text
}

export const checkNewCode = () => {
    const log = loginState.getLoginState();
    let isCalled = false;

    if (typeof log !== "undefined" && log !== null) {
        if (typeof log.authNewInfo !== "undefined" && typeof log.authNewInfo.mfa_code_id !== "undefined"){
            isCalled =true
        }
    }
    return isCalled;
}


export const checkEmailEnabled = () => {
    const log = loginState.getLoginState();
    let isCalled = false;

    if (typeof log !== "undefined" && log !== null) {
        if (typeof log.showEmail !== "undefined" && log.showEmail !== "null" && log.showEmail) {
            isCalled = true;
        }
    }
    return isCalled;
}

/** End Util helper */


/** Button ACTIONS */

const resendCodeData = {
    "auth_mfa" : {
        "channel" : constants.nameVar.SMS
    },
    "client_id": utils.getCurrentClientId(),
    "client_type": utils.getCurrentAppType()
}

const verifyCodeData = {
    "auth_token": {
        "grant_type": "mfa",
        "auth_mfa" : {
            "code" :  '',
            "expires_in": 0
        },
        "client_id": utils.getCurrentClientId(),
        "client_type": utils.getCurrentAppType()
    }
}

export const verifyCode = (e) => {
    const currentId = e && e.currentTarget && e.currentTarget.id;
    const currentVal = getVerifyFromInput();
    return (dispatch, getState) => {
        const loginItems = getState().login;
        if (getVerifyFromInput() === '') {
            dispatch(actions.setLoginErrorType({...loginItems.errorType, [VerifyCodeItems.inputId]: {show: true, msg: VerifyCodeItems.emptyErrorMessage} }));
        } else if (typeof currentVal !== "undefined" && currentVal !== null && (validNumCodeRegex.test(currentVal) === false || currentVal.length !== 6)) {
            dispatch(actions.setLoginErrorType({...loginItems.errorType, [VerifyCodeItems.inputId]: {show: true, msg: VerifyCodeItems.regErrorMessage} }));
        } else {
            SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
            //update data
            //verifyCodeData.auth_token.auth_mfa.mfa_code_id = checkCodeId();
            verifyCodeData.auth_token.auth_mfa.code = getVerifyFromInput();

            dispatch(API.LoginVerifyApi(verifyCodeData, e))
        }
    }
}

export const sendNewCode = (e, type) => {
    return (dispatch, getState) => {
        const loginItems = getState().login;

        if (loginItems.showEmail) {
            //console.log('show popup')
            dispatch(onOpenModal());
        } else { 
            //send new code thru sms
            //console.log('sms sent')
            const currentId = e && e.currentTarget && e.currentTarget.id;
            SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
            dispatch(API.LoginResendCodeApi(resendCodeData, e))
        }
        //console.log('sms: ', getSMSRadioInput(), ' email: ', getEmailRadioInput())
    }
}

export const sendNewCodeThruPopup = (e) => {
    return (dispatch, getState) => {
        dispatch(actions.setLoginAPIError(''));

        //update if selected from email
        if (getEmailRadioInput()) {
            resendCodeData.auth_mfa.channel = constants.nameVar.EMAIL;
            //console.log('send new code thru email')
        } else if (getSMSRadioInput()) {
            resendCodeData.auth_mfa.channel = constants.nameVar.SMS;
            //console.log('send new code thru sms')
        }
        
        const currentId = e && e.currentTarget && e.currentTarget.id;
        SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
        utils.fireClickstreamEvent({'type': 'display', 'name': 'send.new.code.send'});
        dispatch(API.LoginResendCodeApi(resendCodeData, e))
    }

}

export const cancelBtn = () => {
    return (dispatch) => {
        dispatch(resetVerifyError());
        utils.fireClickstreamEvent({type: "click", name: 'auth.mfa.cancel'});
        let url = properties.authServer + '/login';

        const returnUrl = utils.qsSearch().get('returnUrl');

        //add finra flag
        if (utils.isFinra()) {
            if (url.indexOf('?') > -1) {
                url = url + '&skin=FINRA';
            } else {
                url = url + '?skin=FINRA';
            }
        }

        //add forum redirect
        if (utils.isForum()) {
            if (url.indexOf('?') > -1) {
                url = url + '&returnUrl=%2Fsso%3Fservice%3Dhttps%3A%2F%2Fauth.myfico.com%2Fforum-redirect';
            } else {
                url = url + '?returnUrl=%2Fsso%3Fservice%3Dhttps%3A%2F%2Fauth.myfico.com%2Fforum-redirect';
            }
        }

        if (returnUrl !== null && returnUrl !== "") { 
            if (returnUrl.indexOf('apptype=ios') >-1) {
                window.goBack('','');
            } else if (returnUrl.indexOf('apptype=android') > -1) {
                //set apptype because url come from returnURL
                window.apptype = 'android';
                window.AppGoBack();
            } else if (returnUrl.indexOf('product') > -1) {
                window.location.href = 'https:' + properties.securewebserver + '/order/start?product=' + loginActions.getProductId();
            } else {
                window.location.href = url;
            }
        } else {
            window.location.href = url;
        }
    }
}

export const resetVerifyError = () => {
    return (dispatch, getState) => {
        const loginItems = getState().login;

        dispatch(actions.setLoginErrorType({...loginItems.errorType,  ['verify_code']: {show: false}}));
        dispatch(actions.setLoginAPIError(''));
    }
}

export const forgotPasswordLink = (e) => {
    const currentId = e && e.currentTarget && e.currentTarget.id;
    SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);

    utils.fireClickstreamEvent({type: "click", name: 'auth.mfa.having.trouble.logging.in'});
    
    if (loginActions.isMobileAppTypeFromReturnUrl()) {
        document.getElementById('forgot-app').click();
        SimpleButtonAction.buttonIndeterminateProgressToggle(currentId, true);
    } else {
        window.location.href = loginActions.getForgotPasswordLink();
    }
}

/** End Button ACTIONS */

const primaryButton = {
    id: 'verify',
    buttonData: 'text',
    csName: 'auth.mfa.verify',
    text: 'Verify',
    action: verifyCode,
    ariaLabel: 'Verify',
    dispatch: true
} 

const secondCancelButton = {
    id: 'cancel',
    buttonData: 'text',
    csName: 'auth.mfa.cancel',
    text: 'Cancel',
    action: cancelBtn,
    style: 'secondary',
    ariaLabel: 'Cancel',
    dispatch: true
} 

const secondSendCodeButton = {
    id: 'sendcode',
    buttonData: 'text',
    csName: 'auth.mfa.send.new.code',
    text: 'Send New Code',
    action: sendNewCode,
    style: 'secondary',
    ariaLabel: () => checkEmailEnabled() ? 'Open to see send code options': 'Send New Code',
    dispatch: true
} 

const secondForgotButton = {
    id: 'forgot',
    buttonData: 'text',
    csName: 'auth.mfa.having.trouble.logging.in',
    text: 'Having trouble logging in?',
    action: forgotPasswordLink,
    style: 'secondary',
    ariaLabel: 'having trouble logging in',
} 

export const validNumCodeRegex = RegExp(/^\d+$/);

/** End Buttons */

/*** Handlers */

export const onKeyUp = (e) => {
    return (dispatch) => { 
        const getUserId = getVerifyFromInput();

        if (typeof e !== "undefined" && ( e.charCode === 13 || e.code === "Enter") && getUserId !== "") {
            dispatch(verifyCode(e))
            utils.fireClickstreamEvent({type: "click", name: "login.keypress"});
            e.preventDefault();
        }        
    }
}

export const enterInputHandler = (e) => {
    return (dispatch, getState) => { 
        const loginItems = getState().login;
        const {id, value} = e && e.target;
        const val = value.trim();

        //clear api error message
        dispatch(actions.setLoginAPIError(''));

        switch (id) {
            case 'verify_code':
                let codeError = validNumCodeRegex.test(val) ? '' : VerifyCodeItems.regErrorMessage;
                if (val.length === 0) {
                    codeError = VerifyCodeItems.emptyErrorMessage;
                    dispatch(actions.setLoginErrorType({ ...loginItems.errorType, [id]: {show: true, msg: codeError}}));
                } else if (codeError !== '') {
                    dispatch(actions.setLoginErrorType({ ...loginItems.errorType, [id]: {show: true, msg: codeError}}));
                } else {
                    dispatch(actions.setLoginErrorType({...loginItems.errorType,  [id]: {show: false}}));
                }
                break;
            default:
                break;
        }
    }
};

/** End handlers */

/** POPUP */

export const onOpenModal = () => {
    return (dispatch) => { 
        dispatch(actions.setEmailPopInfo({ open: true, popContent}));
        utils.scrollToTop();
        utils.fireClickstreamEvent({'type': 'display', 'name': 'send.new.code'});

        updateCloseBtnAira();
    }
}

export const onCloseModal = () => {
    return (dispatch) => { 
        dispatch(actions.setEmailPopInfo({ open: false, popContent }));
        utils.fireClickstreamEvent({'type': 'display', 'name': 'send.new.code.close'});
    }
}

export const updateCloseBtnAira = () => {
    setTimeout(() => {
        var b = document.querySelector(".react-responsive-modal-closeButton");

        if (b !== null) {
        
                b.setAttribute("aria-label", "close web dialog");
        
        }
    }, 200);
}

export const popContent = {
    info: {
        id: 'mfa-email',
        title: 'Send new code',
        message: LoginVerifySelection,
        primary: true,
        primText: 'Send',
        dialogAL: 'Send new code options',
        primLink: 'send',
        isPrimFunc: true,
        primFunc: (e) => { return sendNewCodeThruPopup(e)}, 
        primClose: true,
        secondary: false,
        secText: 'No',
        secLink: 'close',
        dispatch: true,
        csName: 'send.new.code.send'
    }
}

/** END POPUP */

const LoginVerify = (props) => {

    const dispatch = useDispatch();
    const { location } = props;
    const loginItems = useSelector(state => state.login);
    const open = loginItems.emailPopup && loginItems.emailPopup.open;
    const [svgColor, updateSvgColor] = useState("#fff")
    

    useEffect(() => {
        let isLocationCall = true;

        if (typeof location !== "undefined" && isLocationCall) {
            const locPath = location.pathname;
            logCSPageLoad(locPath);
        }

       //scroll to top
        setTimeout(() => {
            utils.scrollToTop();
        }, 200);
            
        return () => {
            isLocationCall = false;
        }
    }, []);

    const updateSvgHandler = () => {
        if (svgColor === '#fff') {
            updateSvgColor("#00609c");
        } else {
            updateSvgColor("#fff");
        }
    }

    const updateSvgPressHandler = (e) => {
        if (e?.code) {
            if (e.code === 'Space') {
                updateSvgHandler();
            }
        }
    }

    return (
        <React.Fragment> 
            <LoginVerifyMeta />
            <div className="login-wrapper auth-wrapper login-verify-main">
                <div className="login-module auth-module">
                    <h1>Verify login</h1>
                    <div className="text-confirm" aria-live={checkNewCode() ? 'polite' : ''}>
                        {/* {checkNewCode() && <svg data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.attribute_percentage_accounts_paid_as_agreed }}></svg> }   */}
                        <p>{checkText()}</p>
                    </div>

                    <ErrorMessage apiResError={loginItems && loginItems.apiResError} />

                    <form aria-label ="verify login form" action="" onSubmit={(e) => {e.preventDefault()}}>

                        <div data-component="Input" data-type="TextField" id={VerifyCodeItems.id} data-valid={loginItems && loginItems.errorType[VerifyCodeItems.inputId] && !loginItems.errorType[VerifyCodeItems.inputId].show}>
                            <input autoFocus type={VerifyCodeItems.type} id={VerifyCodeItems.inputId} aria-label={VerifyCodeItems.ariaLabel} aria-describedby={VerifyCodeItems.helperId} placeholder=" " onChange={e => dispatch(enterInputHandler(e))} onKeyPress={e => dispatch((onKeyUp(e)))} autoComplete="off" maxLength="6" required />
                            <div className="input-label" aria-hidden="true">{VerifyCodeItems.ariaLabel}</div>

                            <div className="input-helper-container" id={VerifyCodeItems.helperId} aria-live="polite">
                                <div className="error-message">
                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                    {loginItems && loginItems.errorType[VerifyCodeItems.inputId] && loginItems.errorType[VerifyCodeItems.inputId].show && <span className="text-container">{loginItems.errorType[VerifyCodeItems.inputId].msg}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="remember-box">
                            <input onKeyDown={(e) => {updateSvgPressHandler(e)}} type="checkbox" id="rbox" className="cselem" data-cs-type="click" data-cs-name="auth.mfa.remember.me"></input> 
                            <label htmlFor="rbox" onClick={(e) => {updateSvgHandler(e)}} >
                                <svg focusable="false" data-component="Icon" viewBox="0 0 24 24"><g fill="none"><rect className="ui-checkbox-part-box" width="18" height="18" x="3" y="3" stroke="#666666" rx="2"></rect><path className="ui-checkbox-part-check" stroke={svgColor} strokeLinecap="square" strokeWidth="2" d="M7.5 12l2.75 2.75L16.5 8.5"></path></g></svg>
                                <span>Remember this device</span>
                            </label>
                        </div>

                        <div className="full-width-btn">
                            <SimpleButton {...primaryButton} />
                            <SimpleButton {...secondCancelButton} />
                            <SimpleButton {...secondSendCodeButton} />
                            <SimpleButton {...secondForgotButton} />
                        </div>

                        <div className="hide mobile-test">
                            <a id="forgot-app" href={loginActions.getForgotPasswordLink()}>forgot</a>
                        </div>

                    </form>
                </div>
            </div>
            <Modal focusTrapped="true" blockScroll={true} open={open} onClose={() => dispatch(onCloseModal())} center classNames={{overlay: 'myf-simple-overlay', modal: 'page-modal-left-wrapper'}}>
                <SimplePopup info={popContent.info} closeFunc={onCloseModal} />
            </Modal>
        </React.Fragment>
    );
};

export default withRouter(LoginVerify);