import axiosConfig from './../utils/axiosConfig';
import { apiUri, localProp, properties } from './../utils/properties';
import * as utils from './../utils/utilities';
import * as actions from '../store/actions/actions';
import * as constants from '../utils/constants';
import * as LoginVerifyActions from '../containers/LoginVerify/LoginVerify';
import * as SimpleButtonAction from '../components/SimpleButton/SimpleButton';


export const LoginVerifyApi = (data, e) => {
    let verifyURL = "https:" + properties.authServer + apiUri.getNewToken;
    const currentId = e && e.currentTarget && e.currentTarget.id;

    return (dispatch) => {
        let config = {
            headers: {
                Authorization: 'myFICOMFA ' + LoginVerifyActions.checkCodeId()
            }
        }

        //if no data from obj, get it from cookie
        if (LoginVerifyActions.checkCodeId() === "") {
            config = {};
        }

        if (LoginVerifyActions.getCheckboxFromInput()) {
            if (data.auth_token && data.auth_token.auth_mfa) {
                data.auth_token.auth_mfa.rm = true;
            }
        }

        if (typeof data !== "undefined") {
            return axiosConfig.post(verifyURL, data, config)
                .then(res => {
                    if (localProp.testLocal && console && console.log) console.log('post called', res)

                    if (res && res.data && res.data.response) {
                        const curRes = res.data.response;
                        if (typeof curRes.auth_result !== "undefined" && curRes.auth_result === "Authenticated") {
                            if (localProp.testLocal && console && console.log) console.log('res data', res.data)
                            const finalUrl = utils.checkForReUrl();

                            // app requires session before sending to checkout
                            if (utils.isAppEmbeddedView()) {
                                goToCheckout(finalUrl)
                            } else {
                                window.location = finalUrl
                            }

                        }
                    }
                })
                .catch(function (error) {
                    dispatch(errorHandler(error));

                    LoginVerifyActions.focusVerifyInputField();
                    //disable the loader on button
                    SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
                    if (localProp.testLocal && console && console.log) console.log('error', error.response.data.error)
                })
        }
    }
}

//send ticket to app
export const sendAuthTicketToApp = (clientID, authTicketUrl, ticketData, responseUrl) => {
    return axiosConfig.post(authTicketUrl, ticketData)
        .then(res => {
            //send ticket to app
            if (res) {
                window.sendAppTicket(res.data?.response?.ticket);
            }

            //redirect back to sso
            window.location.href = "https:" + properties.authServer + responseUrl
        })
        .catch(function (error) {
            //nothing
        })
}

// Before going to checkout, we need to first create the app session.
export const goToCheckout = (responseUrl) => {
    const apiUrl = apiUri.authTicket;

    if (utils.isAppTypeIOS()) {
        window.getIOSAppClientID(function (responseData) {
            const clientID = responseData.clientID;
            const ticketData = {
                "auth_ticket": {
                    "auth_token_type": 1,
                    "client_id": clientID,
                    "client_type": "MYF_IPHONE"
                }
            };
            sendAuthTicketToApp(clientID, apiUrl, ticketData, responseUrl);
        });
    } else if (utils.isAppTypeAndroid()) {
        const clientID = window.requestAndroidApp(window.androidRequests?.getClientId, "");
        const ticketData = {
            "auth_ticket": {
                "auth_token_type": 1,
                "client_id": clientID,
                "client_type": "MYF_ANDROID"
            }
        };
        sendAuthTicketToApp(clientID, apiUrl, ticketData, responseUrl);
    }
};


export const LoginResendCodeApi = (data, e) => {
    const newCodeURL = "https:" + properties.authServer + apiUri.requestNewMFACode;
    const currentId = e && e.currentTarget && e.currentTarget.id;

    return (dispatch) => {
        let config = {
            headers: {
                Authorization: 'myFICOMFA ' + LoginVerifyActions.checkCodeId()
            }
        }

        if (typeof data !== "undefined") {
            return axiosConfig.post(newCodeURL, data, config)
                .then(res => {
                    if (localProp.testLocal && console && console.log) console.log('post called', res)

                    if (res.data && res.data.response) {
                        const response = res.data.response;
                        dispatch(actions.setAuthNewInfo(response))
                        dispatch(actions.setLoginAPIError(''));

                        //dispatch email option
                        if (response !== '' && typeof response.fallback_channel !== 'undefined'
                            && response.fallback_channel !== null && response.fallback_channel !== ''
                            && response.fallback_channel.toLowerCase() === 'email') {
                            dispatch(actions.setShowEmail(true));
                        }
                    }
                    SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
                })
                .catch(function (error) {
                    //dispatch(errorHandler(error));
                    let obj = {
                        message: ''
                    };
                    if (error && error.response && error.response.data && error.response.data.error && error.response.data.error) {
                        if (error.response.data.error.code && error.response.data.error.code === 'InvalidMFACode') {
                            if (error.response.data.error.message && error.response.data.error.message.indexOf('MFAMaxCodes') > -1) {
                                obj.message = constants.verifyVar.maxCodeMFA;
                                dispatch(actions.setLoginAPIError(obj));
                                utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.mfamaxcodes' });
                            }
                        }
                        else {
                            dispatch(actions.setLoginAPIError(constants.loginErrorMsg.default));
                            utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.default.error' });
                        }
                    } else {
                        utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.default.error' });
                        dispatch(actions.setLoginAPIError(constants.loginErrorMsg.default));
                    }

                    LoginVerifyActions.focusVerifyInputField();
                    //disable the loader on button
                    SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
                    if (localProp.testLocal && console && console.log) console.log('error', error)
                })
        }
    }
}

export const errorHandler = (error) => {
    return (dispatch) => {
        let obj = {
            message: ''
        };
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error) {
            if (error.response.data.error.code && error.response.data.error.code === 'InvalidMFACode') {
                if (error.response.data.error.message && error.response.data.error.message.indexOf('InvalidMFACode') > -1) {
                    //less than 4 times
                    obj.message = constants.verifyVar.invalidMFA;
                    dispatch(actions.setLoginAPIError(obj));
                    utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.invalid.code' });
                    //dispatch(actions.setShowEmail(true));
                } else if (error.response.data.error.message && error.response.data.error.message.indexOf('Fallback_EMAIL') > -1) {
                    //more than 4 times
                    obj.message = constants.verifyVar.invalidMFA;
                    dispatch(actions.setLoginAPIError(obj));
                    //dispatch(actions.setShowEmail(true));
                    utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.fallback.email' });
                    //show popup??
                } else if (error.response.data.error.message && error.response.data.error.message.indexOf('MFAMaxAttempts') > -1) {
                    //more than 9 times
                    obj.message = constants.verifyVar.tooManyMFA;
                    dispatch(actions.setLoginAPIError(obj));
                    utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.mfamaxattempts' });
                }
            } else if (error.response.data.error.code && error.response.data.error.code === 'InvalidRequestData') {
                obj.message = constants.verifyVar.invalidMFA;
                dispatch(actions.setLoginAPIError(obj));
                utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.unrecognized.code' });
            } else {
                dispatch(actions.setLoginAPIError(constants.loginErrorMsg.default));
                utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.default.error' });
            }
        } else {
            dispatch(actions.setLoginAPIError(constants.loginErrorMsg.default));
            utils.fireClickstreamEvent({ 'type': 'display', 'name': 'auth.mfa.default.error' });
        }
        //clear field when error
        LoginVerifyActions.clearVerifyInputField();
    }
}
