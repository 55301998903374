
import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';


import { svgIcons } from '../../utils/svgs';
import {getCurrentAppType, fireClickstreamEvent, trimStr} from '../../utils/utilities';
import * as utils from '../../utils/utilities';
import { logCSPageLoad } from '../../utils/tracking';
import { properties } from '../../utils/properties';
import {validateUserId, validPassword} from './LoginValidation';
import * as Actions from '../../store/actions/actions';
import LoginMeta from './LoginMeta';
import * as SimpleButtonAction from '../../components/SimpleButton/SimpleButton';

import LoginApi from '../../api/LoginApi';
import AppTeaser from './AppTeaser';

const ErrorMessage = loadable(() => import('../../components/ErrorMessage/ErrorMessage'));
const SimpleButton = loadable(() => import('../../components/SimpleButton/SimpleButton'));

export const showThis = () => {
    console.log('clicked');
}

export const passwordItems = {
    id: 'input-container-password',
    inputId: 'input_password',
    ariaLabel: 'Password',
    currentValue: '',
    autoComplete: "current-password",
    toggleBtnAriaLabel: 'show password',
    helperId: 'password_helper',
    emptyErrorMessage: 'Required'
};

export const UserItems = {
    id: 'input-container-userid',
    inputId: 'input_user',
    description: 'This may be your email address.',
    type: 'text',
    autoComplete: "username",
    ariaLabel: 'User ID',
    currentValue: '',
    helperId: 'user_helper',
    emptyErrorMessage: 'Required'
};

export const getUserIdFromInput = () => {
    return document.getElementById(UserItems.inputId) ? document.getElementById(UserItems.inputId).value : "";
}

export const getPasswordFromInput = () => {
    return document.getElementById(passwordItems.inputId) ? document.getElementById(passwordItems.inputId).value : "";
}

export const focusUserInputField = () => {
    if (document.getElementById(UserItems.inputId) !== null) {
        document.getElementById(UserItems.inputId).focus()
    }
}
export const focusPasswordInputField = () => {
    if (document.getElementById(passwordItems.inputId) !== null) {
        document.getElementById(passwordItems.inputId).focus()
    }
}

export const focusInputField= (field) => {
    if (typeof field !== "undefined" && field !== null && document.getElementById(field) !== null) {
        document.getElementById(field).focus()
    }
}

export const resetPasswordField = () => {

    //const hiddenPassword = document.getElementById("hidden_password");
    const password = document.getElementById("input_password");

    if (password !== null) {
        password.value = "";
    }
}

export const forgetPasswordLink = () => {
    var fpLink = properties.authServer + '/login#/';
    window.location = fpLink;
}


export const onKeyUp = (e) => {
    return (dispatch) => { 
        const getUserId = getUserIdFromInput();
        const getPassword = getPasswordFromInput();

        if (typeof e !== "undefined" && ( e.charCode === 13 || e.code === "Enter") && getUserId !== "" && getPassword !== "") {
            dispatch(loginHandler(e))
            fireClickstreamEvent({type: "click", name: "login.keypress"});
            e.preventDefault();
        }        
    }
}

export const loginHandler = (e) => {
    return (dispatch, getState) => { 

        const loginItems = getState().login;
        const getUserId = trimStr(getUserIdFromInput());
        const getPassword = getPasswordFromInput();

        if (getUserId !== "" && getPassword !== "") {
            const isValidUser = validateUserId(getUserId);
            const isValidPass = validPassword(getPassword)

            const data = {
                "auth_token": {
                    "username":getUserId,
                    "password":getPassword,
                    "grant_type":"password",
                    "client_id": "auth-ui",
                    "client_type": getCurrentAppType()
                }
            };

            // handle validation
            if (typeof isValidUser !== 'boolean') {
                dispatch(Actions.setLoginErrorType({...loginItems.errorType, [UserItems.inputId]: {show: true, msg: isValidUser} }));
            } else if (typeof isValidPass !== 'boolean') {
                dispatch(Actions.setLoginErrorType({...loginItems.errorType, [passwordItems.inputId]: {show: true, msg: isValidPass} }));
            } else if (isValidUser === true && isValidPass === true) {
                const currentId = e && e.currentTarget && e.currentTarget.id;
                SimpleButtonAction.buttonIndeterminateProgressToggle(currentId);
                dispatch(LoginApi(data, e));
            } 
        } else {
            //handle empty inputs
            if (getUserId === "" && getPassword === "") {
                dispatch(Actions.setLoginErrorType({...loginItems.errorType, [UserItems.inputId]: {show: true, msg: UserItems.emptyErrorMessage}, [passwordItems.inputId]: {show: true, msg: passwordItems.emptyErrorMessage} }));
                focusUserInputField();
            } else if (getUserId === "") {
                dispatch(Actions.setLoginErrorType({...loginItems.errorType, [UserItems.inputId]: {show: true, msg: UserItems.emptyErrorMessage} }));
                focusUserInputField();
            } else if (getPassword === "") {
                dispatch(Actions.setLoginErrorType({ ...loginItems.errorType,  [passwordItems.inputId]: {show: true, msg: passwordItems.emptyErrorMessage} } ));
                focusPasswordInputField();
            }
        }
    }
}

const primaryButton = {
    id: 'inline-modal-confirm',
    name: 'inline-modal-confirm',
    buttonData: 'text',
    csName: 'login.btn',
    text: 'Log In',
    action: loginHandler,
    ariaLabel: 'Log in',
    dispatch: true
} 

export const passwordMaskingToggle = (e) => {
    return (dispatch, getState) => {
    const loginItems = getState().login;

    const targetId = e && e.target.getAttribute('data-controls');

    const newIsAriaPresssed = loginItems.mask[targetId] && !loginItems.mask[targetId].isAriaPressed;
    const newIcon = loginItems.mask[targetId] && loginItems.mask[targetId].icon === 'show' ? 'hide' : 'show';
    const newType = loginItems.mask[targetId] && loginItems.mask[targetId].type === 'password' ? 'text' : 'password';
    dispatch(Actions.setLoginMasking({
        ...loginItems.mask,
        [targetId]: {
            ...loginItems.mask[targetId],
            isAriaPressed: newIsAriaPresssed,
            icon: newIcon,
            type: newType,
    }}));

    focusInputField(passwordItems.inputId);
    }
};

export const enterInputHandler = (e) => {
    return (dispatch, getState) => { 
        const loginItems = getState().login;
        const {id, value} = e && e.target;
        const val = value.trim();

        //clear api error message
        dispatch(Actions.setLoginAPIError(''));

        switch (id) {
            case 'input_password': case 'input_user':
                if (val.length === 0) {
                    let msgVal = ''
                    if (id === 'input_password') {
                        msgVal = passwordItems.emptyErrorMessage
                    } else {
                        msgVal = UserItems.emptyErrorMessage
                    }

                    dispatch(Actions.setLoginErrorType({ ...loginItems.errorType, [id]: {show: true, msg: msgVal}}));
                }
                else {
                    dispatch(Actions.setLoginErrorType({...loginItems.errorType,  [id]: {show: false}}));
                }
                
                //setEnteredCurrPassword(value);
                break;
            default:
                break;
        }
    }
};

export const getForgotPasswordLink = () => {
    let finraparam = '';
    let url = "https:" + properties.authServer + "/login";
    let productId = getProductId();
    const returnUrl = utils.qsSearch().get('returnUrl');

   //change mobile url
    if (isMobileAppTypeFromReturnUrl()) {
        url = url.replace('http','safari');
    }

    //add finra qs if found
    if (utils.isFinra()) {
        if (url.indexOf('?') > -1) {
            url += '&skin=FINRA';
        } else {
            url += '?skin=FINRA';
        }
    }
    
    url += "#/forgot-password";

     //add productid to url
     if (productId !== "") {
        if (url.indexOf('?') > -1) { 
            url += "&pid=" + productId;
        } else {
            url += "?pid=" + productId;
        }
    }

    return url;
}

export const redirectForgotPasswordLink = () => {
    let finraparam = ''
    // let url = "https:"+ properties.webserver + "/SystemAccess/ForgotMemberInfo.aspx?ln=yes";
    let url = "https:"+ properties.authServer + "/login";
    if (utils.isFinra()) {
        url += '&skin=FINRA';
    }

    url += "#/forgot-password";

    window.location.href = url;
}

export const isMobileAppTypeFromReturnUrl = () => {
    let val = false;
    const returnUrl = utils.qsSearch().get('returnUrl');
    if (returnUrl !== null && returnUrl !== "") {
        if (returnUrl.indexOf('apptype=ios') >-1 ||returnUrl.indexOf('apptype=android') > -1) {
            val = true;
        }
    } 
    return val;
}

export const getProductId = () => {
    let pid = "";
    const prodName = 'product';
    const returnUrl = utils.qsSearch().get('returnUrl');

    //set mobile id
    if (returnUrl !== null && returnUrl !== "") {
        //check for productid
        if (returnUrl.indexOf(prodName) >-1) {
            const splitArray = returnUrl.split('?');
            if (splitArray !== null && splitArray.length > 0) {
                for (var i in splitArray) {
                    if (splitArray[i].indexOf(prodName) > -1) {
                        const getProd = utils.qsCheck("?"+splitArray[i]);
                        pid = getProd.get(prodName);
                        break;
                    }
                }
            }
        }
    }
    return pid;
}

const Login = (props) => {

    const dispatch = useDispatch();
    const { location } = props;
    const loginItems = useSelector(state => state.login);

    useEffect(() => {
        let isLocationCall = true;

        if (typeof location !== "undefined" && isLocationCall) {
            const locPath = location.pathname;
            logCSPageLoad(locPath);
        }

        return () => {
            isLocationCall = false;
        }
    }, []);

    return (
        <React.Fragment> 
            <LoginMeta />
            <div className="bg auth-wrapper login-wrapper login-main" data-testid="login-main">
                <div className="login-module auth-module">
                    <h1>Log in</h1>
                    <p>Welcome back!</p>
                    <form aria-label="log in form" id="login-form" name="login-form" action="" onSubmit={(e) => {e.preventDefault()}}>
                        <ErrorMessage apiResError={loginItems && loginItems.apiResError} />

                        <div data-component="Input" data-type="TextField" id={UserItems.id} data-valid={loginItems && loginItems.errorType[UserItems.inputId] && !loginItems.errorType[UserItems.inputId].show}>
                            <input autoComplete={UserItems.autoComplete} type={UserItems.type} id={UserItems.inputId} name={UserItems.inputId}  aria-label={UserItems.ariaLabel} aria-describedby={UserItems.helperId} placeholder=" " onChange={e => dispatch(enterInputHandler(e))} onKeyPress={e => dispatch((onKeyUp(e)))} required />
                            <div className="input-label" aria-hidden="true">{UserItems.ariaLabel}</div>

                            <div className="input-helper-container" id={UserItems.helperId} aria-live="polite">
                                <div className="description">{UserItems.description}</div>
                                <div className="error-message">
                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                    {loginItems && loginItems.errorType[UserItems.inputId].show && <span className="text-container">{loginItems.errorType[UserItems.inputId].msg}</span>}
                                </div>
                            </div>
                        </div>

                        <div data-component="Input" data-type="Password" id={passwordItems.id} data-valid={loginItems && !loginItems.errorType[passwordItems.inputId].show}>
                                <input type={loginItems && loginItems.mask[passwordItems.inputId].type} id={passwordItems.inputId} name={passwordItems.inputId} data-testid={passwordItems.ariaLabel} aria-label={passwordItems.ariaLabel} autoComplete={loginItems && loginItems.mask[passwordItems.inputId].type && loginItems.mask[passwordItems.inputId].type === "password" ? passwordItems.autoComplete : "off"} autoCapitalize="off" autoCorrect="off" maxLength="128" placeholder=" " onChange={e => dispatch(enterInputHandler(e))} onKeyPress={e => dispatch((onKeyUp(e)))} required />
                                <div className="input-label" aria-hidden="true">{passwordItems.ariaLabel}</div>
                                <div className="input-password-toggle-button-container">
                                    <button
                                        id={`btn_${passwordItems.inputId}`}
                                        data-component="Button"
                                        data-component-type="icon"
                                        aria-pressed={loginItems && loginItems.mask[passwordItems.inputId].isAriaPressed}
                                        data-controls={passwordItems.inputId}
                                        aria-label={passwordItems.toggleBtnAriaLabel}
                                        data-cs-type="click"
                                        data-cs-name="login.password_toggle"
                                        className="cselem"
                                        onClick={e => dispatch((passwordMaskingToggle(e)))}
                                        >
                                        <svg data-component="Icon" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons[loginItems && loginItems.mask[passwordItems.inputId].icon] }}></svg>
                                    </button>
                                </div>
                                <div className="input-helper-container" id={passwordItems.helperId} aria-live="polite">
                                    <div className="description">{passwordItems.description}</div>
                                    <div className="error-message">
                                        <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                        {loginItems && loginItems.errorType[passwordItems.inputId].show && <span className="text-container">{loginItems.errorType[passwordItems.inputId].msg}</span>}
                                    </div>
                                </div>
                        </div>

                        <div className="full-width-btn">
                                {/* <button
                                    id="inline-modal-confirm-btn"
                                    data-testid="inline-modal-confirm-btn"
                                    data-form-action
                                    data-form-container={`form-container-${props.buttonData}`}
                                    type="button"
                                    data-type="submit"
                                    disabled={props.isSubmitted}
                                    data-editable-id={`data-editable-${props.buttonData}`}
                                    data-component="Button"
                                    data-component-type="standard"
                                    data-component-style="primary"
                                    data-cs-type="click"
                                    data-cs-name="login.btn"
                                    className="cselem"
                                    onClick={e => {dispatch(loginHandler())}}
                                    >
                                    <span className="button-content">
                                        <span className="label">Log In</span>
                                    </span>
                                </button> */}

                                <SimpleButton {...primaryButton} />
                        </div>
                    </form>
                    <a data-testid="forgot_password" 
                        className="cselem forgot-pwd" 
                        data-cs-type="click"
                        data-cs-name="login.forgot_password" 
                        data-component-style="text" data-component="Button" data-component-type="standard" 
                        href={getForgotPasswordLink()}>Having trouble logging in?</a>

                    {
                        !utils.isFinra() && <AppTeaser />
                    }

                </div>

                {/* <div className="section hero"></div> */}
            
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);