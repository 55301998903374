import axiosConfig from '../utils/axiosConfig';
import { apiUri, localProp, properties } from '../utils/properties';
import { fireClickstreamEvent } from '../utils/utilities';
import * as Actions from '../store/actions/actions';
import * as Constants from '../utils/constants';

export const replacePhone = (msg, val) => {
    let text = '';
    if (typeof msg !== 'undefined' && msg !== null && typeof val !== 'undefined' && val !== null) {
        text = msg.replace(/\{0\}/g, val);
    }

    return text;
}

export const replaceCsName = (msg, val) => {
    let text = '';
    if (typeof msg !== 'undefined' && msg !== null && typeof val !== 'undefined' && val !== null) {
        text = msg.replace("{1}", val.replace(/[()-]/g,''));
    }

    return text;
}

export const setMsg = (text, cat) => {
    return dispatch => {
        dispatch(Actions.setLoginAPIError(text));
        fireClickstreamEvent({'type': 'display', 'name': 'login.error.msg', 'category': cat});
    }
}

export const defaultErrorMsg = (type) => {
    return (dispatch) => {
        let errorText = Constants.loginErrorMsg.default;
        //replace placeholder if api phone number
        errorText.message = replacePhone(errorText.message, Constants.loginErrorMsg.defaultPhone);
        errorText.message = replaceCsName(errorText.message, Constants.loginErrorMsg.defaultPhone);
        
        dispatch(setMsg(errorText, type && type.toLowerCase()));
    }
}

const supportContact = (type) => {
    const supportURL = "https:" + properties.apiServer + apiUri.supportContactURL;
    let errorText = Constants.loginErrorMsg.default;
    return (dispatch, getState) => {
        return axiosConfig.get(supportURL)
        .then(res => {

            if (localProp.testLocal && console && console.log) console.log('post called', res)
            if (res && res.data && res.data.supportContact) {
                const supportContactList = res.data.supportContact;

                //get general number
                for (var i =0; i < supportContactList.length; i++) {
                    if (supportContactList[i].feature && (supportContactList[i].feature === 'general' || supportContactList[i].feature === 'fraud') && typeof supportContactList[i].datasources === 'undefined') {
                        if (type === "AccountChargeBackLock") {
                            errorText = Constants.loginErrorMsg.AccountChargeBackLock;
                        } else if (type === "AccountAdminLock") {
                            errorText = Constants.loginErrorMsg.AccountAdminLock;
                        }

                        if (typeof supportContactList[i].phone !== 'undefined' && supportContactList[i].phone !== null){
                            //replace placeholder if api phone number
                            errorText.message = replacePhone(errorText.message, supportContactList[i].phone);
                            errorText.message = replaceCsName(errorText.message, supportContactList[i].phone);
                        }
                        dispatch(setMsg(errorText, type.toLowerCase()));
                        
                        break;
                    }
                }
            } else {
                dispatch(defaultErrorMsg(type));
            }
        })
        .catch(function (err) {
            dispatch(defaultErrorMsg(type));

            if (localProp.testLocal && console && console.log) console.log('error', err)
        })
    }
    
}

export default supportContact;