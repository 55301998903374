import React, { useState, useEffect } from "react";
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import './AuthApp.css';
import { sanitizeString } from './utils/utilities';
import Login from './containers/Login/Login';
import LoginVerify from "./containers/LoginVerify/LoginVerify";
import FinraRedeem from "./containers/Finra/FinraRedeem";
import ForumRegister from "./containers/ForumRegister/ForumRegister";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import loadable from '@loadable/component';

const AuthApp = (props) => {
    const { location } = props;
    const [csDebug, setCsDebug] = useState("false")
    useEffect(() => {
        if (location) {
            const locPath = location.pathname;
            const urlParams = new URLSearchParams(location.search);
            let pageInfo = {};

            if (urlParams.has('csdb')) {
                const showCS = sanitizeString(urlParams.get("csdb"));
                if (csDebug !== showCS) {
                    setCsDebug(showCS);

                    if (window && window.myfcsObj) {
                        window.myfcsObj = { ...window.myfcsObj, csDebugEnabled: showCS };
                    }
                }
            }
        }
    }, [location]);

    const [csFirstLoaded, setCSFirstLoaded] = useState(false)
    const updateCS = () => {
        if (typeof window.myfcs == 'object' && typeof window.myfcs.cs_actDebug == 'function') {
            window.myfcs.cs_actDebug(csFirstLoaded);
            if (!csFirstLoaded) {
                if (window && window.myfcsObj) {
                    window.myfcsObj = { ...window.myfcsObj, csDebugEnabled: csDebug };
                }
                setCSFirstLoaded(true);
            }
        }
    }

    const checkDefaultPageUrl = () => {
        const curLoc = window.location.href;
        if (curLoc && curLoc.indexOf('/saveandinvest') > -1) {
            return <FinraRedeem />
        } else if (curLoc && curLoc.indexOf('/forum-register') > -1) {
            return <ForumRegister />
        } else {
            return <Login />
        }
    }

    return (
        <React.Fragment>
            <div id="myf-content">
                <Switch>
                    <Route path="/" exact component={() => checkDefaultPageUrl()} />
                    <Route path="/verify" exact component={() => <LoginVerify />} />
                    <Route path="/saveandinvest" exact component={() => <FinraRedeem />} />
                    <Route path="/forum-register-thankyou" exact component={() => <ForumRegister />} />
                    <Route path="/forum-register" exact component={() => <ForumRegister />} />
                    <Route path="/forgot-password" exact component={() => <ForgotPassword />} />
                    <Route path="/change-password" exact component={() => <ChangePassword />} />
                    {/* load login for other paths */}
                    <Route path="/*" exact component={() => <Login />} />
                </Switch>
            </div>
            {(csDebug && csDebug === "true") && <button onClick={updateCS} data-back="Refresh" data-front="Clickstream" className="btn-flip" id="refresh-cs-btn">&nbsp;</button>}
        </React.Fragment>
    )
}

export default withRouter(AuthApp);