
import * as actionTypeVar from './actionTypes';



export const setLoginMasking = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_PASSWORD_MASKING,obj);
}

export const setLoginErrorType = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_INLINE_ERROR,obj);
}

export const setLoginAPIError = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_API_ERROR,obj);
}

export const setAuthInfo = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_AUTH_INFO,obj);
}

export const setAuthNewInfo = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_AUTH_NEW_INFO,obj);
}

export const setEmailPopInfo = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_EMAIL_POPINFO,obj);
}

export const setShowEmail = (obj) => {
    return setDefaultAction(actionTypeVar.LOGIN_SHOW_EMAIL,obj);
}

export const setFinraErrorType = (obj) => {
    return setDefaultAction(actionTypeVar.FINRA_VOUCHER_ERROR,obj);
}

export const setFinraPageLoaded= (obj) => {
    return setDefaultAction(actionTypeVar.FINRA_VOUCHER_PAGELOADED,obj);
}

/** Generic function */

export const setDefaultAction = (actionType, actionPayload) => {
    let obj = {};

    if (typeof actionType !== "undefined" && actionType !== "") {
        obj.type = actionType;
    }
    if (typeof actionPayload !== "undefined" && actionPayload !== "") {
        obj.payload = actionPayload;
    }
    return obj;
}
