import 'react-app-polyfill/ie11';
import "core-js/stable";

import ReactDOM from 'react-dom';
import AuthApp from './AuthApp';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/states/store';
import history from './utils/history';


const myFICOAuthApp = (
  <Provider store={store}>
    <HashRouter history={history}>
      <AuthApp />
    </HashRouter>
  </Provider>
)

ReactDOM.render(myFICOAuthApp, document.getElementById('myFICOAuthApp'));
