import React from "react";
import { Helmet } from "react-helmet";
import * as utils from '../../utils/utilities';


const FinraMeta = (props) => {
    return (
        <Helmet>
            <title>Redeem your free FICO Score | FINRA Investor Education Foundation</title>
            <meta name="description'" content="Sign in to your myFICO account." />
            <meta name="robots" content="noindex nofollow" />
        </Helmet>
    );
};

export default FinraMeta;