import React from "react";
import { Helmet } from "react-helmet";
import * as utils from '../../utils/utilities';


const ForumRegisterMeta = (props) => {
    return (
        <Helmet>
            <title>Register | myFICO</title>
            <meta name="description'" content="Register for a myFICO account." />
            {/* <meta name="robots" content="noindex nofollow" /> */}
        </Helmet>
    );
};

export default ForumRegisterMeta;