import React from 'react';
import $ from 'jquery';
import { useDispatch } from "react-redux";

export const buttonIndeterminateProgressToggle =(id) => {
    if (typeof id !== "undefined" && id !== null && id !== "") {
        const $obj = $('#' + id);

        if ($obj !== null) {
            const $objNode = $obj[0];
        
            const inProgressSuffix = ' (in progress)';
            const accessibilityText = $obj.attr('aria-label');
            const inProgressAccessibilityText = accessibilityText + ' (in progress)';
            let originalAccessibilityText = '';

            if (typeof accessibilityText !== "undefined" && accessibilityText !== null) {
                originalAccessibilityText = accessibilityText.replace(inProgressSuffix,'');
            }
        
            if (typeof $objNode !== "undefined" && $objNode !== null) {

                const elementWidth = $objNode.getBoundingClientRect().width + "px"; // floating point (not rounded) width is needed
                const isProcessing = $obj.attr('data-progress') === 'true';

                $obj.attr('data-progress', isProcessing ? 'false' : 'true');
                $objNode.classList.toggle("inline-block");
                if (isProcessing) {
                    $objNode.removeAttribute('style');
                    $objNode.removeAttribute('disabled');
                    disableAllButton();
                    $obj.attr('aria-label', originalAccessibilityText);
                } else {
                    $objNode.style.width = elementWidth;
                    disableAllButton(true);
                    $obj.attr('aria-label', inProgressAccessibilityText);
                }
            }
        }
    }
}

export const disableAllButton = (val) => {
    //val = true to disable it
    const $btn = $('button');

    if (typeof $btn !== "undefined" && $btn !== null && $btn.length > 0) {
        for (var i = 0; i < $btn.length; i++) {
            if (typeof val !== "undefined" && val !== null && val === true) {
                $($btn[i]).attr('disabled', true);
            } else {
                $($btn[i])[0].removeAttribute('disabled');
            }
        }
    }
}

export const fireCS = (e) => {
    if (typeof window.fireClickstreamEvent === "function") {
        const id = e && e.currentTarget && e.currentTarget.id; 

        if (typeof id !== "undefined" && id !== null && id !== "") {
            const $obj = $('#' + id);

            const csCat = $obj.attr('data-cs-category');
            const csName = $obj.attr('data-cs-name');

            if (typeof csCat !== "undefined" && csCat !== null && csCat !== "" && csCat.length > 0) {
                window.fireClickstreamEvent({type: "click", name: csName, category: csCat });
            } else {
                window.fireClickstreamEvent({type: "click", name: csName });
            }
        }
    }
}

const SimpleButton = (props) => {
    const {ariaLive, csOff} = props;
    const dispatch = useDispatch();
    let ariaLiveType = ariaLive || "assertive";
    return (
        <button
            id={`sb-${props.id}-btn`}
            name={props.name ? `sb-${props.name}-btn`  : ""}
            data-testid={`sb-${props.id}-btn`}
            data-form-action
            data-form-container={`form-container-${props.buttonData}`}
            type="button"
            data-type="submit"
            aria-live={ariaLiveType}
            aria-label={typeof props.ariaLabel === 'function' ? props.ariaLabel() : props.ariaLabel === props.text ? "" : props.ariaLabel}
            data-target={props.dataTarget} 
            disabled={props.isSubmitted || props.disabled}
            data-editable-id={`data-editable-${props.buttonData}`}
            data-component="Button"
            data-component-type={props.type ? props.type : 'standard'}
            data-component-style={props.style ? props.style : 'primary'}
            data-progress="false"
            data-progress-value="indeterminate"
            data-cs-type="click"
            data-cs-name={typeof props.csName === 'function' ? props.csName() : props.csName ? props.csName : ''}
            data-cs-category={props.csCat ? props.csCat : ''}
            className={props.classes ? props.classes : 'csjdo'}
            onClick={e => {
                    if (typeof props.dispatch !== "undefined" && props.dispatch === true) {
                        dispatch(props.action(e))
                    } else {
                        props.action(e)
                    }
                    if (!csOff) {
                        fireCS(e);
                    }
                    
                }}
            >
            <span className="button-content" aria-hidden={props.ariaLabel === props.text ? false : true}>
                <svg aria-hidden="true" data-component="Icon" className="progress-icon" viewBox="0 0 50 50"><circle cx="50%" cy="50%" r="20"/></svg>
                <span className="label">{props.isLoading ? 'loading...' : props.text}</span>
            </span>
        </button>
    )
}

export default SimpleButton;