// First we need to import axios.js
import axios from 'axios';
//import Cookies from 'js-cookie';
import {properties, localProp} from './properties';


const secureDomain = properties.securewebserver;
//const getAuthToken  = Cookies.get('myFICOAT');

let headerInfo = { 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Expires': '0' };
//setup the local properties
if (localProp.testLocal) {
    headerInfo.Authorization = 'myFICO ' + localProp.authToken;
}

// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    baseURL: secureDomain,
    withCredentials: true,
    //headers: headerInfo
});


// Where you would set stuff like your 'Authorization' header, etc ...
// Don't need to pass auth TOken when in the same domain
//instance.defaults.headers.common['Authorization'] = 'myFICO ' + getAuthToken;
instance.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
//instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// Also add/ configure interceptors && all the other cool stuff

export default instance;