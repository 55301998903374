import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { svgIcons } from '../../utils/svgs';
import { getOptions, fireClickstreamEvent, getCurrentAppType, scrollToTop } from '../../utils/utilities';
import { logCSPageLoad } from '../../utils/tracking';
import ForumRegisterMeta from './ForumRegisterMeta';
import { properties, apiUri } from '../../utils/properties';
import './ForumRegister.css';
import * as inputValidate from './ForumRegisterValidation.js';
import axiosConfig from '../../utils/axiosConfig';

import $ from 'jquery';
import SimpleButton from '../../components/SimpleButton/SimpleButton';

import DOMPurify from "dompurify";

const ForumRegister = (props) => {
    const { location } = props;
    const [isUserCreated, setIsUserCreated] = useState(props.test ? false : null);
    const [showTip, setShowTip] = useState(false);
    const [showManualCityState, setShowManualCityState] = useState(false);
    const [authData, setAuthData] = useState(null);
    const [passValidation, setPassValidation] = useState({
        is_empty: true,
        length: false,
        letter_and_number: false
    });
    const [lastZip, setLastZip] = useState("");
    const [showCityStateDisabled, setShowCityStateDisabled] = useState(true);


    const fnameRef = useRef();
    const lnameRef = useRef();
    const streetRef = useRef();
    const zipRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const pwRef = useRef();
    const rePwRef = useRef();
    const ageRef = useRef();
    const mktRef = useRef();
    const termsRef = useRef();
    
    const INPUT_INFO = {
        firstName: {
            display_name: "First Name",
            elem_id: "input-name-first",
            input_id: "firstName",
            type: "text",
            action: (val) => inputValidate.checkName(val, 1),
            ref: fnameRef
        },
        lastName: {
            display_name: "Last Name",
            elem_id: "input-name-last",
            input_id: "lastName",
            type: "text",
            action: (val) => inputValidate.checkName(val, 2),
            ref: lnameRef
        },
        streetAddress: {
            display_name: "Street Address",
            elem_id: "input-address-street",
            input_id: "streetAddress",
            type: "text",
            action: inputValidate.checkAddress,
            ref: streetRef
        },
        zip: {
            display_name: "ZIP Code",
            elem_id: "input-zip",
            input_id: "zip",
            type: "text",
            action: inputValidate.checkZip,
            ref: zipRef
        },
        city_state: {
            display_name: "City & State",
            elem_id: "input-city-and-state",
            input_id: "city_state"
        },
        city: {
            display_name: "City",
            elem_id: "input-city",
            input_id: "city",
            type: "text",
            action: inputValidate.checkCity,
            ref: cityRef
        },
        state: {
            display_name: "State",
            elem_id: "input-state-select",
            input_id: "state",
            type: "text",
            action: inputValidate.checkState,
            ref: stateRef
        },
        email: {
            display_name: "Email Address",
            elem_id: "input-email",
            input_id: "email",
            type: "email",
            action: inputValidate.checkEmail,
            ref: emailRef
        },
        phone: {
            display_name: "Phone Number",
            elem_id: "input-phone",
            input_id: "phone",
            type: "text",
            action: inputValidate.checkPhone,
            ref: phoneRef
        },
        // gender: {
        //     display_name: "Gender",
        //     elem_id: "input-gender",
        //     input_id: "gender",
        //     ref: genderRef
        // },
        // logonName: {
        //     display_name: "Create User ID",
        //     elem_id: "input-user-id",
        //     input_id: "logonName",
        //     type: "text",
        //     action: inputValidate.checkLogonName
        // },
        password: {
            display_name: "Create Password",
            elem_id: "input-pass",
            input_id: "password",
            type: "password",
            action: inputValidate.checkPassword,
            ref: pwRef
        },
        rePassword: {
            display_name: "Re-enter Password",
            elem_id: "input-re-pass",
            input_id: "rePassword",
            type: "password",
            action: inputValidate.checkRePassword,
            ref: rePwRef
        },
        age18Plus: {
            display_name: "",
            elem_id: "input-age-18-plus",
            input_id: "age18Plus",
            type: "checkbox",
            ariaLabel: "I am 18 years of age or older",
            text: "I'm 18 years of age or older",
            error_text: "You must be 18 years of age or older.",
            ref: ageRef
        },
        mktOpt: {
            display_name: "",
            elem_id: "input-mkt-opt",
            input_id: "mktOpt",
            type: "checkbox",
            ariaLabel: "Yes, I agree checkbox",
            text: "Yes, sign me up to receive email about special offers, valuable information, and educational resources to help me manage my credit.",
            ref: mktRef,
            optional: true
        },
        forumTerms: {
            display_name: "",
            elem_id: "input-forum-terms",
            input_id: "forumTerms",
            type: "checkbox",
            ariaLabel: "Yes, I have read and understand the Forums User Guidelines and Terms of Service",
            text: 'Yes, I have read and understand the <a class="cselem" href="javascript:;" data-cs-type="click" data-cs-name="forum.terms" onClick="window.open(\'https://ficoforums.myfico.com/t5/User-Guidelines-General/myFICO-Forums-Terms-of-Service/td-p/4\', \'_blank\')">Forums User Guidelines and Terms of Service</a>',
            error_text: "You must accept Forums User Guidelines and Terms of Service",
            ref: termsRef
        }
    }

    const [inputErrMsg, setInputErrMsg] = useState({
        firstName: '',
        lastName: '',
        streetAddress: '',
        zip: '',
        city_state: '',
        city: '',
        state: '',
        email: '',
        phone: '',
        // logonName: '',
        password: '',
        rePassword: '',
        age18Plus: '',
        forumTerms: ''
    })

    useEffect(() => {
        let isLocationCall = true;

        if (typeof location !== "undefined" && isLocationCall) {
            const locPath = location.pathname;
            logCSPageLoad(locPath);
            if (window.location.pathname.indexOf("thankyou") > -1) {
                setIsUserCreated(true);
            } else {
                setIsUserCreated(false);
            }
        }

        return () => {
            isLocationCall = false;
        }
    }, []);

    useEffect(() => {
        if (isUserCreated !== null && !isUserCreated) {
            const authTokenURL = "https:" + properties.authServer + apiUri.getNewToken;
            axiosConfig.get(authTokenURL).then(res => {
                if (res && res.data && res.data.response && res.data.response.auth_result && res.data.response.auth_result === 'Authenticated') {
                    window.location.replace("/forum-redirect");
                }
            }).catch(function (err) {
            })
        }
    }, [isUserCreated])

    useEffect(() => {
        if (showManualCityState) {
            cityRef.current.focus();
        }
    }, [showManualCityState])

    const setCityStateValue = (cityVal, stateVal) => {
        cityVal = cityVal || "";
        // default to AL state as its the first item get selected by default
        stateVal = stateVal || "AL";
        // reset city and state flag
        if (cityVal) {
            cityRef.current.value = cityVal;
            stateRef.current.value = stateVal;
        } else {
            setShowManualCityState(true);
            setLastZip("");
        }
    }

    useEffect(() => {
        if (authData) {
            const authTokenURL = "https:" + properties.authServer + apiUri.getNewToken;
            axiosConfig.post(authTokenURL, authData).then(res => {
                window.location.replace("/forum-register-thankyou");
            }).catch(function (err) {
                fireClickstreamEvent({ 'type': 'log', 'name': 'forum.register.authtoken', 'category': JSON.stringify(err) });
            })
        }
    }, [authData])
    const populateCityAndState = () => {

        if (zipRef.current.value != lastZip && !inputValidate.checkZip(zipRef.current.value)) {
            if (showManualCityState) {
                setShowManualCityState(false);
            }

            if (props.test && props.test === true) {
                setCityStateValue();
            } else {
                var envUrl = properties.apiServer
                // var envUrl = "//api.qa1.myfico.com";
                var apiUrl = apiUri.getZip;
                var zipLookupData = { "zipCode": zipRef.current.value };

                // checkingZip = true;
                setLastZip(zipRef.current.value);
                $.ajax({
                    url: envUrl + apiUrl,
                    type: "POST",
                    contentType: 'application/json',
                    data: JSON.stringify(zipLookupData)
                }).then(function (data) {
                    if (data && data.length == 0) {
                        //SetStatusMessage('divZipStatus', 'Invalid Zip Code');
                        setCityStateValue();
                    } else {
                        const ddlElem = document.getElementById(INPUT_INFO.city_state.elem_id);
                        ddlElem.innerHTML = "";
                        if (data) {
                            if (data.length > 1) {
                                ddlElem.append($("<option></option>").attr("value", "").text("Select"));
                            }

                            Object.values(data).map((addr, i) => {
                                var opt = document.createElement('option');
                                opt.value = `${addr.city},${addr.state}`;
                                opt.innerHTML = `${addr.city}, ${addr.state}`;
                                ddlElem.appendChild(opt);
                                setCityStateValue(addr.city, addr.state);
                            })

                            var optOther = document.createElement('option');
                            optOther.value = `other`;
                            optOther.innerHTML = `Other`;
                            ddlElem.appendChild(optOther);
                            ddlElem.disabled = false;
                            setShowCityStateDisabled(false);

                            var new_ddlElem = ddlElem.cloneNode(true);
                            ddlElem.parentNode.replaceChild(new_ddlElem, ddlElem);

                            new_ddlElem.addEventListener('change', function () {
                                if (this.value === "other") {
                                    setCityStateValue();
                                }
                            });
                        }
                    }
                }).catch(function (e) {
                    setCityStateValue();
                });
            }

        }
    }

    const setFieldErrorMessage = (fieldName, msg) => {
        setInputErrMsg((prevState) => ({ ...prevState, [fieldName]: msg }))
    }

    const [isContinueDisabled, setIsContinueDisabled] = useState(false)
    const continueHandler = () => {
        if (!isContinueDisabled) {
            setIsContinueDisabled(true);

            let has_invalid_input = false,
                firstEncounter = "",
                pwObj = {};
            const validateInput = (inputName) => {
                let errMsg = "";
                console.log("FAILED AT", inputName)
                let value = INPUT_INFO[inputName].ref.current.value;
                if (inputName === "age18Plus") {
                    value = INPUT_INFO[inputName].ref.current.checked;
                    errMsg = !value ? INPUT_INFO.age18Plus.error_text : "";
                } else if (inputName === "password") {
                    if (value) {
                        pwObj = INPUT_INFO.password.action(value);
                        setPassValidation(pwObj);
                        if (pwObj.is_empty || !pwObj.length || !pwObj.letter_and_number) {
                            errMsg = true; //to pass check
                        }
                    } else {
                        errMsg = `${INPUT_INFO[inputName].display_name} is required.`;
                    }
                } else if (inputName === "rePassword") {
                    if (value) {
                        errMsg = (value !== INPUT_INFO.password.ref.current.value) ? "Password does not match." : "";
                    } else {
                        errMsg = `${INPUT_INFO[inputName].display_name} is required.`;
                    }
                } else if (inputName === "forumTerms") {
                    value = INPUT_INFO[inputName].ref.current.checked;
                    errMsg = !value ? INPUT_INFO.forumTerms.error_text : "";
                } else {
                    if (value) {
                        errMsg = INPUT_INFO[inputName].action(value);
                    } else {
                        errMsg = `${INPUT_INFO[inputName].display_name} is required.`;
                    }
                }

                if (errMsg) {
                    has_invalid_input = true;
                    if (!firstEncounter) {
                        firstEncounter = inputName;
                    }

                    if (typeof errMsg === "string") {
                        if (inputName === "forumTerms") {
                            //forums term ignore formatting
                        } else {
                            errMsg = errMsg.toLowerCase();
                            errMsg = `${errMsg.substring(0, 1).toUpperCase()}${errMsg.substring(1)}`;
                        }
                        if (inputName === INPUT_INFO.zip.input_id) {
                            errMsg = errMsg.replace("Zip", "ZIP");
                        }

                        setInputErrMsg((prevState) => ({ ...prevState, [inputName]: errMsg }))
                    }
                }
            }

            for (let inputItem in INPUT_INFO) {
                if (INPUT_INFO[inputItem].hasOwnProperty("ref") && !(INPUT_INFO[inputItem].hasOwnProperty("optional") && INPUT_INFO[inputItem].optional)) {
                    validateInput(inputItem);
                }
            }

            if (!has_invalid_input) {
                const data = {
                    "firstName": fnameRef.current.value,
                    "lastName": lnameRef.current.value,
                    "address": {
                        "streetAddress1": streetRef.current.value,
                        "city": cityRef.current.value,
                        "state": stateRef.current.value,
                        "zip": zipRef.current.value
                    },
                    "phoneNumber": phoneRef.current.value.replace(/\D/g, ''),
                    "email": emailRef.current.value,
                    "logonName": emailRef.current.value,
                    "password": pwRef.current.value,
                    "age18Confirmed": ageRef.current.checked,
                    "emailOptIn": mktRef.current.checked,
                    "forumTermsAccepted": termsRef.current.checked,
                    "clientID": "secure",
                    "clientType": "MYF_WEB",
                    "forumUser": true
                };

                const personalinfoUri = apiUri.personalInfo;

                $.ajax({
                    url: personalinfoUri,
                    type: "POST",
                    contentType: 'application/json',
                    data: JSON.stringify(data),
                    xhrFields: { withCredentials: true },
                    crossDomain: true,
                    timeout: 120000,
                    error: function (err, statusTxt) {
                        //handles input error
                        setIsContinueDisabled(false);
                        // var message = "Uh oh! We've encountered an error. Please try again."

                        if (err && err.responseJSON && err.responseJSON.error) {
                            if (err.responseJSON.error.invalid_fields) {
                                var errorObj = err.responseJSON.error.invalid_fields;
                                for (var x = 0, y = errorObj.length; x < y; x++) {
                                    var errText = errorObj[x].reason;
                                    var inputName = "pi-" + errorObj[x].name;

                                    //check for email
                                    if (errorObj[x].name == "logonName") {
                                        inputName = INPUT_INFO.email.input_id;
                                        setFieldErrorMessage(inputName, errText)
                                        document.getElementById(INPUT_INFO[inputName].elem_id).focus();
                                    }
                                    // no log for for field error
                                }
                            } else {
                                if (err && err.responseJSON && err.responseJSON.error) {
                                    fireClickstreamEvent({ 'type': 'log', 'name': 'forum.register.error', 'category': JSON.stringify(err.responseJSON.error) });
                                }
                            }
                        } else {
                            if (err && err.error && err.error.code) {
                                fireClickstreamEvent({ 'type': 'log', 'name': 'forum.register.error', 'category': err.error.code });
                            }
                        }

                        //handles timeout error
                        if (statusTxt === "timeout") {
                            fireClickstreamEvent({ 'type': 'log', 'name': 'forum.register.error', 'category': "timeout" });
                        }
                    },
                    success: function (obj) {
                        // handle success
                        fireClickstreamEvent({ 'type': 'log', 'name': 'forum.register.success' });
                        setAuthData({
                            "auth_token": {
                                "username": emailRef.current.value,
                                "password": pwRef.current.value,
                                "grant_type": "password",
                                "client_id": "auth-ui",
                                "client_type": getCurrentAppType()
                            }
                        });
                    },
                    complete: function (data) {
                        setIsContinueDisabled(false);
                    }
                });
            } else {
                document.getElementById(INPUT_INFO[firstEncounter].elem_id).focus();
                setIsContinueDisabled(false);
            }
        }
    }

    const primaryButton = {
        id: 'register-continue',
        buttonData: 'text',
        csName: 'continue.btn',
        text: 'Continue',
        classes: 'csjdo button cta',
        action: continueHandler,
        ariaLabel: 'Continue'
    }

    const onBlurInputValidation = (e) => {
        setTimeout(() => {
            const { id, value, dataset } = e.target;
            const inputName = dataset.inputName;
            let validationMsg = "";
            // set the value on state on onblur

            // setInputInfo((prevState) => ({ ...prevState, [inputName]: value }));

            if (document.activeElement !== e.target) {
                if (value == "" && inputName !== "") {
                    if (INPUT_INFO[inputName].display_name) {
                        let displayName = INPUT_INFO[inputName].display_name;
                        displayName = displayName.toLowerCase();
                        displayName = `${displayName.substring(0, 1).toUpperCase()}${displayName.substring(1)}`;
                        if (inputName === INPUT_INFO.zip.input_id) {
                            displayName = displayName.replace("Zip", "ZIP");
                        }
                        // if (inputName === INPUT_INFO.logonName.input_id) {
                        //     displayName = displayName.replace("id", "ID");
                        // }

                        if (inputErrMsg[inputName] === "" || value === "") {
                            validationMsg = `${displayName} is required.`;
                        }

                    } else {
                        validationMsg = "Required";
                    }
                } else {
                    if (inputName) {
                        if (inputName === INPUT_INFO.zip.input_id) {
                            validationMsg = INPUT_INFO[inputName].action(value);
                            populateCityAndState();
                        } else if (inputName === INPUT_INFO.rePassword.input_id) {
                            if (pwRef.current.value !== rePwRef.current.value) {
                                validationMsg = "Password does not match.";
                            }
                        } else {
                            // only password field is skipped from onblur validate as its handled by a different ui
                            if (inputName !== INPUT_INFO.password.input_id) {
                                if (inputName === INPUT_INFO.phone.input_id) {
                                    let phoneVal = phoneRef.current.value = phoneRef.current.value.replace(/\D/g, '');
                                    if (phoneVal.length === 10) {
                                        phoneRef.current.value = `${phoneVal.substring(0, 3)} ${phoneVal.substring(3, 6)} ${phoneVal.substring(6, 10)}`
                                    }
                                }
                                validationMsg = INPUT_INFO[inputName].action(value);
                            }

                        }
                    }
                }
                if (inputErrMsg[inputName] !== validationMsg) {
                    setFieldErrorMessage(inputName, validationMsg)
                }

            }
        }, 150)
    };

    const chkboxToggleHandler = (e) => {
        const { dataset } = e.target;
        const inputName = dataset.inputName;
        if (e.target.checked) {
            setFieldErrorMessage(inputName, "")
        }
    };

    const stateChangeHandler = (e) => {
        const { value, dataset } = e.target;
        const inputName = dataset.inputName;
        if (value !== stateRef.current.value) {
            // setInputInfo((prevState) => ({ ...prevState, state: e.target.value }))
            stateRef.current.value = value;
            setFieldErrorMessage(inputName, "")
        }
    }

    const passHandler = () => {
        const pwObj = INPUT_INFO.password.action(pwRef.current.value);
        setPassValidation(pwObj);
    };

    // const enterInputHandler = (e) => {
    //     const pwObj = INPUT_INFO.password.action(value);
    //     setPassValidation(pwObj);


    //     if (inputInfo[inputName] !== value) {
    //         // setInputInfo((prevState) => ({ ...prevState, [inputName]: value }));
    //         if (inputName === INPUT_INFO.password.input_id) {
    //             // setInputInfo((prevState) => ({ ...prevState, [inputName]: value }));
    //             const pwObj = INPUT_INFO.password.action(value);
    //             setPassValidation(pwObj);
    //         }

    //         if (zipRef.current.value && !cityRef.current.value) {
    //             populateCityAndState();
    //             setShowManualCityState(true);
    //             console.log("III TRIGGERED")
    //         }
    //     }
    // };

    const toggleTipHandler = () => {
        if (!showTip) { setShowTip(true); }
    }

    const zipChangeHandler = () => {

        let currentVal = zipRef.current.value;

        if (currentVal.length > 5) {
            currentVal = currentVal.substring(0, 5);
        }

        zipRef.current.value = currentVal = currentVal.replace(/\D/g, '');

        if (currentVal.length === 5 && currentVal !== lastZip) {
            populateCityAndState();
        }
    }
    const emailChangeHandler = () => {
        if (emailRef.current.value.length > 80) {
            emailRef.current.value = emailRef.current.value.substring(0, 5);
        }
    }
    const phoneChangeHandler = () => {
        let currentVal = phoneRef.current.value;
        phoneRef.current.value = currentVal = currentVal.replace(/\D/g, '');
        if (currentVal.length > 10) {
            phoneRef.current.value = currentVal.substring(0, 10);
        }
    }

    const onPhoneFocusHandler = () => {
        let currentVal = phoneRef.current.value;
        phoneRef.current.value = currentVal = currentVal.replace(/\D/g, '');
    }

    const allowTag = {
        ADD_TAGS: ['a', 'p'],
        ADD_ATTR: ['onclick']
    };

    return (
        <div id="forum-register">
            <ForumRegisterMeta />
            {isUserCreated !== null && !isUserCreated &&
                <div id="forum-reg" className={`purchase-path-card`}>
                    <div className="header"><h1 className="title">Forum Registration</h1><div className="icon icon-secure" aria-hidden="true"><svg aria-label="Your information is secure badge" dangerouslySetInnerHTML={{ __html: svgIcons.secure }}></svg></div></div>
                    <div className="body layout-2-col">
                        <form noValidate>
                            <div className="form-group" role="group" aria-labelledby="sec-profile">
                                <h2 id="sec-profile">Profile</h2>
                                <div className={`form-item ${inputErrMsg.firstName ? "error" : ""}`}>
                                    <label htmlFor={INPUT_INFO.firstName.elem_id} className="form-label">{INPUT_INFO.firstName.display_name}</label>
                                    <input aria-describedby={`${INPUT_INFO.firstName.elem_id}-err`} aria-invalid={inputErrMsg.firstName ? true : false} onBlur={onBlurInputValidation} ref={fnameRef} data-input-name={INPUT_INFO.firstName.input_id} type={INPUT_INFO.firstName.type} data-testid={INPUT_INFO.firstName.elem_id} id={INPUT_INFO.firstName.elem_id} className="textfield" required aria-required="true" />
                                    <div className={`form-item-message ${inputErrMsg.firstName ? "error" : ""}`} role="alert">
                                        <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                        <span data-testid={`${INPUT_INFO.firstName.elem_id}-err`} id={`${INPUT_INFO.firstName.elem_id}-err`}>{inputErrMsg.firstName}</span>
                                    </div>
                                </div>
                                <div className={`form-item ${inputErrMsg.lastName ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.lastName.elem_id} className="form-label">{INPUT_INFO.lastName.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.lastName.elem_id}-err`} aria-invalid={inputErrMsg.lastName ? true : false} onBlur={onBlurInputValidation} ref={lnameRef} data-input-name={INPUT_INFO.lastName.input_id} type={INPUT_INFO.lastName.type} data-testid={INPUT_INFO.lastName.elem_id} id={INPUT_INFO.lastName.elem_id} className="textfield" required aria-required="true" />
                                        <div className={`form-item-message ${(inputErrMsg.lastName) ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.lastName.elem_id}-err`} id={`${INPUT_INFO.lastName.elem_id}-err`}>{inputErrMsg.lastName}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-item full ${inputErrMsg.streetAddress ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.streetAddress.elem_id} className="form-label">{INPUT_INFO.streetAddress.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.streetAddress.elem_id}-err`} aria-invalid={inputErrMsg.streetAddress ? true : false} onBlur={onBlurInputValidation} ref={streetRef} data-input-name={INPUT_INFO.streetAddress.input_id} type={INPUT_INFO.streetAddress.type} data-testid={INPUT_INFO.streetAddress.elem_id} id={INPUT_INFO.streetAddress.elem_id} className="textfield" required aria-required="true" />
                                        <div className={`form-item-message ${inputErrMsg.streetAddress ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.streetAddress.elem_id}-err`} id={`${INPUT_INFO.streetAddress.elem_id}-err`}>{inputErrMsg.streetAddress}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`form-item ${inputErrMsg.zip ? "error" : ""}`}>
                                    <div className="form-item-2-col-inner-wrapper">
                                        <div className="form-field">
                                            <label htmlFor={INPUT_INFO.zip.elem_id} className="form-label">{INPUT_INFO.zip.display_name}</label>
                                            <input ref={zipRef} aria-describedby={`${INPUT_INFO.zip.elem_id}-err`} aria-invalid={inputErrMsg.zip ? true : false} onBlur={onBlurInputValidation} onChange={zipChangeHandler} ref={zipRef} data-input-name={INPUT_INFO.zip.input_id} type={INPUT_INFO.zip.type} data-testid={INPUT_INFO.zip.elem_id} id={INPUT_INFO.zip.elem_id} placeholder="Enter ZIP Code for city and state" className="textfield" required aria-required="true" inputMode="numeric" maxLength="5" pattern="[0-9]*" />
                                        </div>
                                    </div>
                                    <div className={`form-item-message ${inputErrMsg.zip ? "error" : ""}`} role="alert">
                                        <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                        <span data-testid={`${INPUT_INFO.zip.elem_id}-err`} id={`${INPUT_INFO.zip.elem_id}-err`}>{inputErrMsg.zip}</span>
                                    </div>
                                </div>
                                <div className={`form-item ${(inputErrMsg.city || inputErrMsg.state || inputErrMsg.city_state) ? "error" : ""}`}>
                                    <div className={`form-field ${INPUT_INFO.city_state.elem_id} ${showManualCityState ? "hide" : ""}`} aria-hidden={showManualCityState ? true : false} data-testid={`form-item-${INPUT_INFO.city_state.elem_id}`} id={`form-item-${INPUT_INFO.city_state.elem_id}`}>
                                        <label htmlFor={INPUT_INFO.city_state.elem_id} className={`form-label  ${showCityStateDisabled ? "disabled-state" : ""}`}>{INPUT_INFO.city_state.display_name}</label>
                                        <select aria-describedby={`${INPUT_INFO.city_state.elem_id}-err`} className="select-item" name="" data-testid={INPUT_INFO.city_state.elem_id} id={INPUT_INFO.city_state.elem_id} disabled>
                                        </select>
                                        <label htmlFor={INPUT_INFO.city_state.elem_id} className="select-label select-drop-arrow"></label>
                                        <div className={`form-item-message ${inputErrMsg.city_state ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.city_state.elem_id}-err`} id={`${INPUT_INFO.city_state.elem_id}-err`}>{inputErrMsg.city_state}</span>
                                        </div>
                                    </div>

                                    <div className={`form-item-2-col-inner-wrapper ${!showManualCityState ? "hide" : ""}`} aria-hidden={!showManualCityState ? true : false} id="form-item-input-city-and-state">
                                        <div className="form-field">
                                            <label htmlFor={INPUT_INFO.city.elem_id} className="form-label">{INPUT_INFO.city.display_name}</label>
                                            <input aria-describedby={`${INPUT_INFO.city.elem_id}-err`} ref={cityRef} aria-invalid={inputErrMsg.city ? true : false} onBlur={onBlurInputValidation} ref={cityRef} data-input-name={INPUT_INFO.city.input_id} type={INPUT_INFO.city.type} data-testid={INPUT_INFO.city.elem_id} id={INPUT_INFO.city.elem_id} className="textfield" required aria-required="true" />
                                        </div>
                                        <div className={`form-field input-name-suffix ${INPUT_INFO.state.elem_id}`}>
                                            <label htmlFor={INPUT_INFO.state.elem_id} className="form-label">State</label>
                                            <select name="" data-testid={INPUT_INFO.state.elem_id} id={INPUT_INFO.state.elem_id} className="" data-input-name={INPUT_INFO.state.input_id} onBlur={onBlurInputValidation} ref={stateRef} onChange={stateChangeHandler}>
                                                <option value="" defaultValue="" className="disabled">Select</option>
                                                {getOptions(properties.stateArr)}
                                            </select>
                                            <label htmlFor="input-name-suffix" className="select-label"></label>
                                        </div>
                                    </div>
                                    <div className={`form-item-message ${(inputErrMsg.city || inputErrMsg.state) ? "error" : ""} ${!showManualCityState ? "hide" : ""}`} role="alert">
                                        <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                        {(inputErrMsg.city && inputErrMsg.state) ?
                                            <span data-testid={`${INPUT_INFO.city.elem_id}-err`} id={`${INPUT_INFO.city.elem_id}-err`}>City and State is required.</span>
                                            :
                                            <span data-testid={`${INPUT_INFO.city.elem_id}-err`} id={`${INPUT_INFO.city.elem_id}-err`}>{inputErrMsg.city}{inputErrMsg.state}</span>
                                        }
                                    </div>
                                </div>
                                {/* <div className={`form-item ${inputErrMsg.email ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.email.elem_id} className="form-label">{INPUT_INFO.email.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.email.elem_id}-err`} aria-invalid={inputErrMsg.email} onBlur={onBlurInputValidation} ref={REPLACEME} data-input-name={INPUT_INFO.email.input_id} value={emailRef.current.value} type={INPUT_INFO.email.type} data-testid={INPUT_INFO.email.elem_id} id={INPUT_INFO.email.elem_id} className="textfield" required aria-required="true" pattern=".+@globex\.com" />
                                        <div className={`form-item-message ${inputErrMsg.email ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.email.elem_id}-err`} id={`${INPUT_INFO.email.elem_id}-err`}>{inputErrMsg.email}</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className={`form-item ${inputErrMsg.phone ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.phone.elem_id} className="form-label">{INPUT_INFO.phone.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.phone.elem_id}-err`} aria-invalid={inputErrMsg.phone ? true : false} onBlur={onBlurInputValidation} onChange={phoneChangeHandler} ref={phoneRef} onFocus={onPhoneFocusHandler} data-input-name={INPUT_INFO.phone.input_id} type={INPUT_INFO.phone.type} data-testid={INPUT_INFO.phone.elem_id} id={INPUT_INFO.phone.elem_id} className="textfield" required aria-required="true" inputMode="numeric" />
                                        <div className={`form-item-message ${inputErrMsg.phone ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.phone.elem_id}-err`} id={`${INPUT_INFO.phone.elem_id}-err`}>{inputErrMsg.phone}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-item space-holder" aria-hidden="true"></div>
                            </div>
                            <div className="form-group" role="group" aria-labelledby="sec-login">
                                <h2 id="sec-login">Create login</h2>

                                {/* <div className={`form-item ${inputErrMsg.logonName ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.logonName.elem_id} className="form-label">{INPUT_INFO.logonName.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.logonName.elem_id}-err`} autoCapitalize="off" autoCorrect="off" aria-invalid={inputErrMsg.logonName} onBlur={onBlurInputValidation} ref={REPLACEME} data-input-name={INPUT_INFO.logonName.input_id} value={inputInfo.logonName} type={INPUT_INFO.logonName.type} data-testid={INPUT_INFO.logonName.elem_id} id={INPUT_INFO.logonName.elem_id} className="textfield" required aria-required="true" />
                                        <div className={`form-item-message ${inputErrMsg.logonName ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.logonName.elem_id}-err`} id={`${INPUT_INFO.logonName.elem_id}-err`}>{inputErrMsg.logonName}</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className={`form-item ${inputErrMsg.email ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.email.elem_id} className="form-label">{INPUT_INFO.email.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.email.elem_id}-err`} aria-invalid={inputErrMsg.email ? true : false} onBlur={onBlurInputValidation} onChange={emailChangeHandler} ref={emailRef} data-input-name={INPUT_INFO.email.input_id} type={INPUT_INFO.email.type} data-testid={INPUT_INFO.email.elem_id} id={INPUT_INFO.email.elem_id} className="textfield" required aria-required="true" maxLength="80" pattern=".+@globex\.com" />
                                        <div className={`form-item-message ${inputErrMsg.email ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.email.elem_id}-err`} id={`${INPUT_INFO.email.elem_id}-err`}>{inputErrMsg.email}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* create empty state */}
                                <div className="form-item space-holder" aria-hidden="true"></div>
                                <div className={`form-item ${inputErrMsg.password ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.password.elem_id} className="form-label">{INPUT_INFO.password.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.password.elem_id}-err`} onFocus={toggleTipHandler} autoCapitalize="off" autoCorrect="off" aria-invalid={(inputErrMsg.password === "" && passValidation.length && passValidation.letter_and_number) ? false : true} onBlur={onBlurInputValidation} ref={pwRef} onChange={passHandler} data-input-name={INPUT_INFO.password.input_id} type={INPUT_INFO.password.type} data-testid={INPUT_INFO.password.elem_id} id={INPUT_INFO.password.elem_id} className="textfield" required aria-required="true" />
                                        {/* <button type="button" className="interactive js-mask-toggle" role="button" aria-label={`${inputInfo.showPassword ? "Click to to mask password" : "Click to unmask password"}`} onClick={passToggle}>
                                            {inputInfo.showPassword ? 
                                                <span className="label" dangerouslySetInnerHTML={{ __html: svgIcons.hide }}></span>
                                                :
                                                <span className="label" dangerouslySetInnerHTML={{ __html: svgIcons.show }}></span>
                                            }
                                        </button> */}
                                        <div className={`form-item-message ${inputErrMsg.password ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.password.elem_id}-err`} id={`${INPUT_INFO.password.elem_id}-err`}>{inputErrMsg.password}</span>
                                        </div>
                                        {showTip &&
                                            <div className="validation-message">
                                                <ul className="" empty={`${passValidation.is_empty}`} aria-live="assertive" aria-atomic="true">
                                                    <li className="tip">
                                                        <strong>Tip:</strong> Use uppercase, lowercase and numbers for a stronger password.
                                                </li>
                                                    <li className="pw-critera" id="message-length" valid={`${passValidation.length}`}>
                                                        {passValidation.length ?
                                                            <svg aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.valid_check }}></svg>
                                                            :
                                                            <svg aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.valid_uncheck }}></svg>
                                                        }
                                                        <strong>Must be</strong> 8-128 characters
                                                    {/* {passValidation.length ?
                                                        <span className="accessibility-text"> - Criteria met</span>
                                                        :
                                                        <span className="accessibility-text"> - Criteria not met</span>
                                                    } */}
                                                    </li>
                                                    <li className="pw-critera" id="message-letters-and-numbers" valid={`${passValidation.letter_and_number}`}>
                                                        {passValidation.letter_and_number ?
                                                            <svg aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.valid_check }}></svg>
                                                            :
                                                            <svg aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.valid_uncheck }}></svg>
                                                        }
                                                        <strong>Must</strong> contain a mix of uppercase, lowercase, numbers, and special characters. (At least 3 types)
                                                    {/* {passValidation.letter_and_number ?
                                                        <span className="accessibility-text"> - Criteria met</span>
                                                        :
                                                        <span className="accessibility-text"> - Criteria not met</span>
                                                    } */}
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`form-item ${inputErrMsg.rePassword ? "error" : ""}`}>
                                    <div className="form-field">
                                        <label htmlFor={INPUT_INFO.rePassword.elem_id} className="form-label">{INPUT_INFO.rePassword.display_name}</label>
                                        <input aria-describedby={`${INPUT_INFO.rePassword.elem_id}-err`} autoCapitalize="off" autoCorrect="off" aria-invalid={inputErrMsg.rePassword ? true : false} onBlur={onBlurInputValidation} ref={rePwRef} data-input-name={INPUT_INFO.rePassword.input_id} type={INPUT_INFO.rePassword.type} data-testid={INPUT_INFO.rePassword.elem_id} id={INPUT_INFO.rePassword.elem_id} className="textfield" required aria-required="true" />
                                        {/* <button type="button" className="interactive js-mask-toggle" role="button" aria-label={`${inputInfo.showPassword ? "Click to mask password" : "Click to unmask password"}`} onClick={passToggle}>
                                            {inputInfo.showPassword ?
                                                <span className="label" dangerouslySetInnerHTML={{ __html: svgIcons.hide }}></span>
                                                :
                                                <span className="label" dangerouslySetInnerHTML={{ __html: svgIcons.show }}></span>
                                            }
                                        </button> */}
                                        <div className={`form-item-message ${inputErrMsg.rePassword ? "error" : ""}`} role="alert">
                                            <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                            <span data-testid={`${INPUT_INFO.rePassword.elem_id}-err`} id={`${INPUT_INFO.rePassword.elem_id}-err`}>{inputErrMsg.rePassword}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" role="group" aria-labelledby="sec-acknowledge">
                                <h2 id="sec-acknowledge" className="accessibility-text">Acknowledgements</h2>
                                <div className={`form-item forum-item age-section full ${inputErrMsg.age18Plus ? "error" : ""}`}>
                                    <div className="ui-checkbox-wrapper indented">
                                        <label className="ui-checkbox-label">
                                            <input type={INPUT_INFO.age18Plus.type} id={INPUT_INFO.age18Plus.elem_id} data-input-name={INPUT_INFO.age18Plus.input_id} ref={ageRef} onChange={chkboxToggleHandler} />
                                            <span aria-hidden="true" focusable="false" role="img" aria-label={INPUT_INFO.age18Plus.ariaLabel} xmlns="http://www.w3.org/2000/svg" version="1.1" dangerouslySetInnerHTML={{ __html: svgIcons.br_checked }}></span>
                                        </label>
                                        <span>
                                            <label htmlFor={INPUT_INFO.age18Plus.elem_id}>{INPUT_INFO.age18Plus.text}</label>
                                        </span>
                                    </div>
                                    <div className={`form-item-message ${inputErrMsg.age18Plus ? "error" : ""}`} role="alert">
                                        <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                        <span data-testid={`${INPUT_INFO.age18Plus.elem_id}-err`} id={`${INPUT_INFO.age18Plus.elem_id}-err`}>{inputErrMsg.age18Plus}</span>
                                    </div>
                                </div>
                                <div className="form-item email-section opt-mkt full" aria-hidden="<%= isFree %>">
                                    <div className="ui-checkbox-wrapper indented">
                                        <label className="ui-checkbox-label">
                                            <input type={INPUT_INFO.mktOpt.type} id={INPUT_INFO.mktOpt.elem_id} defaultChecked={true} data-input-name={INPUT_INFO.mktOpt.input_id} ref={mktRef} />
                                            <span aria-hidden="true" focusable="false" role="img" aria-label={INPUT_INFO.mktOpt.ariaLabel} xmlns="http://www.w3.org/2000/svg" version="1.1" dangerouslySetInnerHTML={{ __html: svgIcons.br_checked }}></span>
                                        </label>
                                        <span>
                                            <label htmlFor={INPUT_INFO.mktOpt.elem_id}>{INPUT_INFO.mktOpt.text}</label>
                                        </span>
                                    </div>
                                </div>
                                <div className={`form-item forum-item agree-section full ${inputErrMsg.forumTerms ? "error" : ""}`}>
                                    <div className="ui-checkbox-wrapper indented">
                                        <label className="ui-checkbox-label">
                                            <input type={INPUT_INFO.forumTerms.type} id={INPUT_INFO.forumTerms.elem_id} data-input-name={INPUT_INFO.forumTerms.input_id} ref={termsRef} onChange={chkboxToggleHandler} />
                                            <span aria-hidden="true" focusable="false" role="img" aria-label={INPUT_INFO.forumTerms.ariaLabel} xmlns="http://www.w3.org/2000/svg" version="1.1" dangerouslySetInnerHTML={{ __html: svgIcons.br_checked }}></span>
                                        </label>
                                        <span>
                                            <label htmlFor={INPUT_INFO.forumTerms.elem_id}  dangerouslySetInnerHTML={{  __html:  DOMPurify.sanitize(INPUT_INFO.forumTerms.text, allowTag) }}></label>
                                        </span>
                                    </div>
                                    <div className={`form-item-message ${inputErrMsg.forumTerms ? "error" : ""}`} role="alert">
                                        <svg className="icon" viewBox="0 0 8 8" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.error_input }}></svg>
                                        <span data-testid={`${INPUT_INFO.forumTerms.elem_id}-err`} id={`${INPUT_INFO.forumTerms.elem_id}-err`}>{inputErrMsg.forumTerms}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer layout-2-col">
                        <div className="slot-1">
                            <SimpleButton disabled={isContinueDisabled} {...primaryButton} />
                        </div>
                        <div className="slot-2">
                            {/* <div className="button link nowrap">Need help? <a href="tel:1-800-319-4433">Call 1-800-319-4433</a></div> */}
                        </div>
                    </div>
                </div>
            }

            {isUserCreated &&
                <div id="confirmation-register" className={`purchase-path-card`}>
                    <div>
                        <h1>Welcome!</h1>
                        <h2>Thanks for signing up!</h2>
                        <p>You've successfully created a myFICO account and can now join FICO&reg; Forums. You can also use your User ID and Password to access all myFICO products and features.</p>
                        <a id="go-to-forum" data-component="Button" data-component-type="standard" data-component-style="primary" className="cselem" data-cs-type="click" data-cs-name="go.to.forum" href="/forum-redirect"><span>Go to FICO&reg; Forums</span></a>
                    </div>
                </div>
            }

        </div>
    )
}
export default withRouter(ForumRegister);