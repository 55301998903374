import { properties } from "./properties"

export const nameVar = {
    dynanmicprops: 'Dynamic Props',
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    required: 'requried'
}

export const subjectVar = {

}

export const verifyVar = {
    missing: 'Voucher code is required.',
    mobileConfirm: 'We texted you a verification code. Enter the code to verify your identity.',
    resendSuccess: 'We sent you a new code to {destination}. Please enter this code before it expires in {min} minutes. Any previous codes are no longer valid.',
    SMSSuccess: 'We sent you a code to {phone}. Enter the code to verify your identity.',
    EMAILSuccess: 'We sent you a code to {email}. Enter the code to verify your identity.',
    invalidMFA: 'That code is not valid. It may be a typo, expired, or replaced with a new code. Please try again.',
    tooManyMFA: 'That code is not valid. Your account has too many failed attempts to enter the verification code. For your security, your account is suspended from logging in. Please try again in 24 hours.',
    maxCodeMFA: 'Your account has too many recent requests for verification codes. For your security, your account is suspended from logging in. Please try again in 24 hours.',
    newCodeMFA: 'New code sent. Please enter this code before it expires. Any previous codes are no longer valid.'
}

export const loginErrorMsg = {
    ExternalApiInvocationFailed: {"message":'Uh oh. We\'ve encounter a technical error while attempting to retrieve your account. Please try again later.'},
    AccountOtherLock: {"message":"For your security, your account has been locked. Please <a href=\" https:"+properties.webserver+"/support/contactus?subject=How%20do%20I%20unlock%20my%20account%3F\" class=\"cselem\" data-cs-type=\"click\" data-cs-name=\"login.error.msg\" data-cs-category=\"account_other_lock\">contact us</a> to unlock your account. "},
    AccountAdminLock: {"message":"For your security, your account has been locked. Please call Customer Care at <a href=\"tel:1-800-546-1650\" class=\"cselem\" data-cs-type=\"click\" data-cs-name=\"login.error.phone\" data-cs-category=\"18005461650\">1-800-546-1650</a> to unlock your account."},
    AccountChargeBackLock: {"message":"For your security, your account has been locked. Please call Customer Care at <a href=\"tel:{0}\" class=\"cselem\" data-cs-type=\"click\" data-cs-name=\"login.error.phone\" data-cs-category=\"{1}\">{0}</a> to unlock your account."},
    AccountLocked: {"message":"Your account has been locked due to too many failed login attempts. Please <a href=\" https:"+properties.webserver+"/support/contactus?subject=How%20do%20I%20unlock%20my%20account%3F\" class=\"cselem\" data-cs-type=\"click\" data-cs-name=\"login.error.msg\" data-cs-category=\"account_locked\">contact us</a> to unlock your account."},
    ForgotMemberInfo: {"message":"You have entered an invalid User ID and password combination multiple times. <a href=\" https:"+ properties.webserver + "/SystemAccess/ForgotMemberInfo.aspx?ln=yes&\" class=\"cselem\" data-cs-type=\"click\" data-cs-name=\"login.error.msg\" data-cs-category=\"forgot_member_info\">Forgot your User ID/email and password?</a>"},
    LockOut: {"message":"You have had too many issues with verifying your login. For your security, your account is suspended from logging in from new devices."},
    FailedLogin: {"message":'User ID and password do not match. Please try again.'},
    default: {"message":'Uh oh! We\'ve encountered an error. Please try again.'},
    defaultPhone: '1-800-319-4433'
}

