import React from 'react';
import { useDispatch } from "react-redux";

import "react-responsive-modal/styles.css";
import './SimplePopup.css';

export const checkLink = (link, props) => {
    if (typeof link !== "undefined" && link !== null) {
        if (link === 'close') {
            props && props.closeFunc();
        } else if (props && props.info && props.info.isPrimFunc) {
            props.info.primFunc();

            //close after clicking on primary
            if (props.info.primClose) {
                props && props.closeFunc();
            }
        } else {
            window.location.href=link;   
        }
    }

    return (dispatch) => {  
        if (props && props.info && props.info.isPrimFunc) {
            dispatch(props.info.primFunc());

            //close after clicking on primary
            if (props.info.primClose) {
                props && dispatch(props.closeFunc());
            }
        }

    }
}

const SimplePopup = (props) => {
    const dispatch = useDispatch();

    return (
        <div id={`modal-${props.info && props.info.id}`} role="dialog" 
            data-testid={`modal-${props.info && props.info.id}`} 
            aria-labelledby={`modal-title-modal-${props.info && props.info.id}`} 
            aria-describedby={`modal-describedby-modal-${props.info && props.info.id}`}
            aria-label={props.info && props.info.dialogAL}
            className="modal left">
            <h1>{props.info && props.info.title}</h1>
            <div className="content-wrapper container">
                {
                    props.info && props.info.message && typeof props.info.message === 'string' ?
                        <p>{props.info && props.info.message}</p>   
                    :
                        <props.info.message /> 
                }
                
                <div className="action full-width-btn"> 
                    {props.info && props.info.primary && 
                        <button onClick={e => {
                                if (typeof props.info.dispatch !== "undefined" && props.info.dispatch === true) {
                                    dispatch(checkLink(props.info && props.info.primLink, props))
                                } else {
                                    checkLink(props.info && props.info.primLink, props) 
                                }
                            }}
                            className="primary link cselem" 
                            data-cs-type="click" 
                            data-cs-name={props.info.csName ? props.info.csName : "primary.click"}
                            data-cs-category={props.info.primText}
                            data-form-action="true" 
                            data-form-container="form-container-text"
                            aria-label={props.info.primText} 
                            data-component="Button" data-component-type="standard" data-component-style="primary" 
                            >{props.info.primText}</button>
                    }
                
                    {props.info && props.info.secondary ? 
                        <button onClick={() => checkLink(props.info && props.info.secLink, props)} className="secondary link cselem"  data-cs-type="click" data-cs-name="secondary.click" data-cs-category={props.info.secText}>{props.info.secText}</button>
                        : "" 
                    }
                </div>
            </div>
        </div>
        
    )
}

export default SimplePopup;